/* eslint-disable react/jsx-no-bind */
import { h, Component } from 'preact';
import { Button } from '../../shared/button';
import { UseSparklesIcon } from '../../shared/svg/icons/sparkles';
import { trackNavClick } from '../../shared/tracking/google';
import { Link } from '../../shared/link';
import styles from './styles.scss';
import { Config } from '../../shared/context';
import { NOTIFICATION_COMPONENT_TYPES } from './notification-components';

/**
 * Returns a formatted Date string in format 'Oct 4, 2019'
 * @param {Date} date - instance of the Date object
 * @returns {String} formatted string with date
 */
const getFormattedDate = (date) =>
  new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(date);

// add commas to long number strings
const getFormattedNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Converts a Date object into a "time ago" string
 * @param {Date} date - instance of the Date object
 * @returns {String} formatted string representing the "time ago"
 */
const toTimeAgo = (date) => {
  const nowUnix = Date.now() / 1000;
  const timeDiffUnix = nowUnix - date.valueOf() / 1000;
  let timeAgo;

  // less than an hour
  if (timeDiffUnix < 3600) {
    const timeDiffMin = Math.round(timeDiffUnix / 60);

    if (timeDiffMin < 2) {
      timeAgo = '1 minute ago';
    } else {
      timeAgo = timeDiffMin + ' minutes ago';
    }
  } else if (timeDiffUnix < 3600 * 24) {
    // less than 24 hours
    const timeDiffHour = Math.round(timeDiffUnix / 3600);

    if (timeDiffHour < 2) {
      timeAgo = '1 hour ago';
    } else {
      timeAgo = timeDiffHour + ' hours ago';
    }
  } else {
    // more than 24 hours
    timeAgo = 'on ' + getFormattedDate(date);
  }

  return timeAgo;
};

class UnreadIcon extends Component {
  render({ count }) {
    return <div className={styles.unreadIcon}> {count} </div>;
  }
}

class Notification extends Component {
  clickHandler({ onClick, label, location, url, ga }) {
    return async evt => {
      evt.preventDefault();
      trackNavClick({ label, location, ga });
      await onClick(evt);
      window.location = url;
    };
  }

  getNotificationTitle(type, meta) {
    switch (type) {
    case 'post_promote':
      return <span> Post Promoted </span>;
    case 'ipa_trophy_added':
    case 'teaser_trophy_added':
      return <span> Trophy earned! </span>;
    case 'comments_new_reply':
      return <span> New comment reply! </span>;
    default:
      break;
    }

    if (meta.points) {
      return (
        <span>
          <UseSparklesIcon
            className={styles.sparklesSVG}
            height={14}
            width={14}
            aria-hidden={true}
          />
          {getFormattedNumber(meta.points)} Internet Points earned!
        </span>
      );
    }

    return '';
  }

  getNotificationBody(type, meta, trackingData) {
    if (NOTIFICATION_COMPONENT_TYPES[type]) {
      const NotificationComponent = NOTIFICATION_COMPONENT_TYPES[type];
      return <NotificationComponent {...meta} trackingData={trackingData} />;
    }

    return <span>You have a new notification!</span>;
  }

  render({
    id_seq,
    onClick = () => {},
    label,
    location,
    meta,
    type,
    url,
    triggered_at,
    isTeaser,
    teaserName,
    newFeature = false,
    trackingData = {},
    index
  }) {
    trackingData['position_in_unit'] = index;
    return (
      <li>
        <Config.Consumer>
          {(config) => (
            <div className={styles.notification}>
              <Link
                href={url}
                location={'userMenu'}
                label={
                  isTeaser ? 'teaser-notification-link' : 'notification-link'
                }
                onClick={this.clickHandler({
                  onClick,
                  label,
                  location,
                  url,
                  ga: config.ga,
                })}
                trackingData={{
                  'item_name': isTeaser ? teaserName : id_seq,
                  ...trackingData
                }}
              >
                <div className={styles.notificationTitle}>
                  {this.getNotificationTitle(type, meta)}
                  {newFeature ? (
                    <span className={styles.newFeatureBadge}>New Feature</span>
                  ) : null}
                  <span className={styles.notificationTimestamp}>
                    {toTimeAgo(new Date(triggered_at))}
                  </span>
                </div>
                {this.getNotificationBody(type, meta, trackingData)}
              </Link>
            </div>
          )}
        </Config.Consumer>
      </li>
    );
  }
}

export class Notifications extends Component {
  render({
    unreadNotifications,
    readNotifications,
    totalUnreadCount,
    onMarkAsReadClicked,
  }) {
    const hasUnreadNotifications = unreadNotifications.length > 0;
    const hasReadNotifications = readNotifications.length > 0;

    return (
      <div className={styles.notifications}>
        <h3 className={styles.notificationsTitle}>Notifications</h3>
        {hasUnreadNotifications && (
          <Button
            className={styles.markAllAsReadButton}
            onClick={() =>
              onMarkAsReadClicked(
                unreadNotifications[0],
                true,
                unreadNotifications
              )
            }
            location={'userMenu'}
            label={'notification-read-all'}
          >
            Dismiss all
          </Button>
        )}
        <div className={styles.unreadTitleContainer}>
          <h4 className={styles.unreadTitle}>Unread</h4>
          {hasUnreadNotifications && <UnreadIcon count={totalUnreadCount} />}
        </div>
        {hasUnreadNotifications ? (
          <div className={styles.unreadNotificationsContainer}>
            <ul>
              {unreadNotifications.map((notification, index) => {
                const mark = () => onMarkAsReadClicked(notification, false);
                return (
                  <Notification
                    onClick={mark}
                    location={'userMenu'}
                    label={'notification-read'}
                    index={index}
                    {...notification}
                  />
                );
              })}
            </ul>
            {unreadNotifications.some(
              (n) => n.type === 'teaser_trophy_added'
            ) && (
              <div className={styles.teaserTrophyInfo}>
                <span role="img" aria-label="finger">
                  ☝️
                </span>
                <b> Hey, you earned a trophy! </b> Trophies are awarded to
                BuzzFeed Community members to celebrate their accomplishments.{' '}
                <a href="/annakopsky/internet-points-2019">
                  <b>Learn more here</b>
                </a>
                .
              </div>
            )}
          </div>
        ) : (
          <div className={styles.notificationEmptyState}>
            No unread notifications.
          </div>
        )}
        {hasReadNotifications && (
          <div>
            <h4 className={styles.readTitle}>Read</h4>
            <div className={styles.readNotificationsContainer}>
              <ul>
                {readNotifications.map((notification, index) => (
                  <Notification {...notification} index={index}/>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}
