import { h } from 'preact';
import BringMeBrandNav from './bringme';

const brandNavComponents = {
  bringme: {
    component: BringMeBrandNav,
    condition: (edition) => ['au', 'in', 'us', 'uk', 'ca'].indexOf(edition) > -1
  }
};

const BrandNav = ({ edition, brand }) => {
  const hasNavComponent = typeof brand !== 'undefined' && typeof brandNavComponents[brand] !== 'undefined';
  const meetsNavCondition = hasNavComponent && brandNavComponents[brand].condition(edition);

  if (!hasNavComponent || !meetsNavCondition) {
    return null;
  }

  const NavComponent = brandNavComponents[brand].component;
  return (<NavComponent/>);
};

export default BrandNav;
