import cookies from '@buzzfeed/bf-utils/lib/cookies';

const DEFAULT_HEADERS = {
  'Content-type': 'application/json',
  'X-XSRF': cookies.get('_xsrf', ''),
};

// const UPSTREAM = 'http://localhost:3000/product-api/v1';
const UPSTREAM = '/product-api/v1';

const getItemsByLinkIds = async({linkIds}) => {
  if (!linkIds || linkIds.length < 1) {
    return null;
  }
  const linksStr = linkIds.slice(0, 5).join(',');
  try {
    return fetch(`${UPSTREAM}/wishlist-links?link_ids=${linksStr}`, {
      headers: DEFAULT_HEADERS,
    }).then((response) => {
      if (!response.ok) {
        return null;
      }
      return response.json();
    });
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};


// Store new link ids to users wishlist
async function add({ linkIds }) {
  let fetchBody = [];

  linkIds.forEach(linkId => {
    fetchBody.push({
      // eslint-disable-next-line camelcase
      link_id: linkId,
    });
  });
  try {
    const response = await fetch(`${UPSTREAM}/wishlist/item`, {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(fetchBody),
    });

    return await apiResponse(response);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

// Remove link ids from users wishlist
async function remove({ ids }) {
  let fetchBody = [];

  ids.forEach(id => {
    fetchBody.push({
      id: id,
    });
  });
  try {
    const response = await fetch(`${UPSTREAM}/wishlist/item`, {
      method: 'DELETE',
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(fetchBody),
    });
    return await apiResponse(response);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

// Get link ids from users wishlist
async function get({ userId, wishlistId, params = {} }) {
  const validParams = [
    'with_product',
    'item_page',
    'item_page_size',
    'buzz_id',
  ];
  let request = `${UPSTREAM}/wishlist?user_id=${userId}`;

  if (wishlistId) {
    request += `&id=${wishlistId}`;
  }
  for (const [key, value] of Object.entries(params)) {
    if (validParams.includes(key)) {
      request += `&${key}=${value}`;
    }
  }

  try {
    const response = await fetch(request, {
      method: 'GET',
      headers: DEFAULT_HEADERS
    });
    return await apiResponse(response);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

// Handle fetch responses
async function apiResponse(response) {
  try {
    if (!response.ok) {
      throw Error(`${response.status} ${response.statusText}`);
    }

    let results = await response.json();
    return results['results'] ? results['results'] : results;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export { add, remove, get, getItemsByLinkIds };

