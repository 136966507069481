import { h, Component } from 'preact';
import { FlyoutContainer } from '../../shared/flyout';
import { ThemedMoreNav } from '../more-nav';
import styles from './styles.scss';
import { wrapper } from '../header.scss';
import { withTheme } from '../../shared/withTheme';

class MainNav extends Component {
  render({ navItems, theme, showMoreNav, isSticking, children }) {
    return (
      <div
        className={`${styles.mainNavContainer} ${theme.mainNavContainer} js-main-nav
          ${isSticking ? styles.stickyMainNav : ''}
        `}
      >
        <div className={wrapper}>
          <div className={styles.mainNav}>
            {children}
          </div>
          <ThemedMoreNav
            navItems={navItems}
            isVisible={showMoreNav}
          />
        </div>
        <FlyoutContainer />
      </div>
    );
  }
}

export const ThemedMainNav = withTheme(MainNav);
