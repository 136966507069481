import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  width: 210,
  height: 50,
  id: 'asis-brand-logo'
};

export const AsIsBrandLogo = () => (
  <symbol {...attributes}>
    <path d="M174.462.216v46.94h-7.87V.216h7.87zm19.674 11.597c6.625 0 10.906 2.485 13.736 6.282l-5.04 4.833c-1.656-2.486-4.83-4.28-9.11-4.28-3.384 0-5.654 1.242-5.654 3.381 0 2.554 2.754 3.176 8.145 4.349 5.867 1.311 11.74 3.036 11.74 10.63 0 7.594-5.728 10.975-14.011 10.975-6.558 0-12.427-2.139-15.326-6.971l5.108-4.901c2.141 3.589 6.214 5.108 10.7 5.108 3.521 0 5.943-1.105 5.943-3.728 0-2.692-2.486-3.314-7.662-4.418-5.385-1.105-12.219-3.107-12.219-10.7-.019-6.487 5.09-10.56 13.65-10.56zM20.69.365L.1 47.305h9.088l3.728-8.536.397-.92h22.422l.397.92 3.73 8.536h9.088L28.355.365H20.69zm3.798 10.292l8.07 19.782H16.484l8.004-19.782zm43.006 15.868c-5.383-1.172-8.145-1.794-8.145-4.348 0-2.14 2.277-3.382 5.653-3.382 4.28 0 7.455 1.795 9.112 4.28l5.04-4.832c-2.831-3.798-7.112-6.282-13.739-6.282-8.559 0-13.667 4.073-13.667 10.561 0 7.594 6.835 9.596 12.22 10.7 5.175 1.105 7.661 1.726 7.661 4.418 0 2.622-2.416 3.728-5.943 3.728-4.493 0-8.552-1.519-10.7-5.11l-5.095 4.907c2.9 4.831 8.767 6.972 15.326 6.972 8.282 0 14.012-3.383 14.012-10.975 0-7.593-5.868-9.323-11.735-10.637z" fill="#0A116D" />
    <path fill="#88F2C6" d="M156.573.11L88.72 39.732v9.06L156.573 9.17z" />
  </symbol>
);

export const UseAsIsBrandLogo = ({...props}) => (
  <SvgUse {...attributes} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
