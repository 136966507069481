import { h } from 'preact';
import { SvgUse } from '../';

const attributes = {
  id: 'wishlist-icon',
  width: 38,
  height: 35
};

const attributesAnimated = {
  id: 'wishlist-icon-animated',
  width: 15,
  height: 14
};

export const WishlistIcon = () => (
  <symbol {...attributes}>
    <path d="M27.1 5.1c3.8 0 6.9 2.8 6.9 6.2v.1c0 1.6 0 4.9-3.9 8.8L19 31.3l-11.1-11C4 16.3 4 13 4 11.4v-.1c0-3.4 3.1-6.2 6.9-6.2 2.2 0 3.2.6 5.2 2.7l2.8 2.8 2.8-2.8c2.2-2.1 3.1-2.7 5.4-2.7m0-4c-3.7 0-5.6 1.4-8.1 3.8-2.4-2.4-4.3-3.8-8.1-3.8C5.1 1.1 0 5.5 0 11.3c0 1.9-.1 6.6 5.1 11.8L17 35c.4.5 1.2 1 2 1s1.6-.5 2-1l11.9-11.9c5.2-5.2 5.1-9.9 5.1-11.8 0-5.8-5.1-10.2-10.9-10.2z"/>
  </symbol>
);

export const WishlistIconAnimated = () => (
  <symbol {...attributesAnimated}>
    <g clipPath="url(#clip0_591_7245)">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.191 0C8.79961 0 8.08512 0.561564 7.145 1.52424C6.24249 0.561564 5.528 0 4.09902 0C1.91794 0 5.13772e-05 1.76491 5.13772e-05 4.09139C5.13772e-05 4.85352 -0.0375017 6.73876 1.91794 8.82457L6.39291 13.5979C6.54333 13.7984 6.84416 13.999 7.145 13.999C7.44584 13.999 7.74668 13.7984 7.89709 13.5979L12.3721 8.82457C14.3275 6.73876 14.2899 4.85352 14.2899 4.09139C14.2899 1.76491 12.3721 0 10.191 0Z" fill="#222222"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1906 1.60452C11.6196 1.60452 12.7853 2.72765 12.7853 4.09145V4.13156C12.7853 4.77335 12.7853 6.09703 11.3187 7.66139L7.14461 12.1138L2.97049 7.7015C1.50391 6.09703 1.50391 4.77335 1.50391 4.13156V4.09145C1.50391 2.72765 2.66965 1.60452 4.09863 1.60452C4.92594 1.60452 5.30198 1.84519 6.05408 2.68754L7.10701 3.81067L8.15994 2.68754C8.98724 1.84519 9.32569 1.60452 10.1906 1.60452Z" fill="#E40C78"/>
    </g>
    <defs>
      <clipPath id="clip0_591_7245">
        <rect width="14.29" height="13.999" fill="white"/>
      </clipPath>
    </defs>
  </symbol>
);

export const UseWishlistIcon = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props}/>
);

export const UseWishlistIconAnimated = ({...props}) => (
  <SvgUse id={attributesAnimated.id} viewBox={`0 0 ${attributesAnimated.width} ${attributesAnimated.height}`} {...props}/>
);
