/** @jsx h */
import { h, render } from 'preact';
import Header from './header';
import { headerContainerId } from './app-bfo-server';

function renderHeader(container) {
  render(<Header {...window.BZFD.__HEADER_STATE__} />, container, container.firstElementChild);
  delete window.BZFD.__HEADER_STATE__;
}


if (typeof window !== 'undefined') {
  // TEMPORARY MEASURE - we will delete any bf_auth_session cookie that does not have the httpOnly flag
// This should help clean up a problem where auth proxies were incorrectly setting a cookie on www.buzzfeed.com
// The correct cookie is on buzzfeed.com and has the httpOnly flag
  document.cookie = 'bfauth_session=;Max-Age=-99999';

  let container = document.querySelector(`#${headerContainerId}`);

  if (container && window.BZFD && window.BZFD.__HEADER_STATE__) {
    renderHeader(container);
  } else {
    window.addEventListener('DOMContentLoaded', () => {
      container = document.querySelector(`#${headerContainerId}`);
      renderHeader(container);
    });
  }
}
