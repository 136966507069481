import { h } from 'preact';

export const BaggyPeace = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="-100 -100 920 920"
    xmlSpace="preserve"
  >
    <g opacity="0.3">
      <path
        fill="#FFFFFF"
        d="M615.45,169.02c-3.88-9.07-7.88-18.09-11.84-27.12c-0.31-0.3-0.56-0.68-0.7-1.2c-0.09-0.32-0.17-0.64-0.25-0.96
	c-2-4.56-3.98-9.12-5.93-13.7c-0.21-0.19-0.4-0.42-0.55-0.71c-0.83-1.6-1.49-3.19-1.98-4.81c-2.4-2.43-3.29-5.35-3.32-8.55
	c-0.73-1.81-1.47-3.62-2.18-5.44c0.8-2.93,1.34-5.91,1.26-9.02c-0.21-8.08-8.88-10.54-14.11-7.34c-0.23-3.92-1-7.86-3.81-10.79
	c-6.84-7.14-15.51-3.41-20.35,3.12c-4.68-12.95-7.71-26.56-11.05-39.88c-2.82-11.25-20.18-6.48-17.36,4.79
	c3.5,13.95,6.58,28.32,11.72,41.78c0.03,0.08,0.06,0.15,0.09,0.23c-3.1-5.15-6.27-10.89-12.64-11.22c-1.17-0.06-2.44,0.22-3.62,0.74
	c-4.45-5.95-8.79-11.98-14.51-16.78c-3.75-3.15-9.03-3.69-12.73,0c-3.24,3.24-3.76,9.57,0,12.73c8.99,7.54,14.24,18.39,22.43,26.7
	c1.85,1.87,3.77,3.67,5.71,5.45c-1.97,5.39-2.64,11.43-2.71,17.07c-0.14,11.47,2.27,23.16,6.65,33.72
	c6.17,14.85,14.82,28.42,21.84,42.85c2.17,4.46,4.33,9.25,6.72,13.96c0.1,0.28,0.2,0.56,0.3,0.83c-4.47,4.55-11.77,7.7-17.46,10.31
	c-9.47,4.35-19.09,8.26-28.43,12.92c-11.17,5.57-23.1,12.44-29.1,23.86c-5.5,10.46-4.48,22.52-2.25,33.7
	c2.4,12.02,9.84,23.58,20.19,30.2c8.94,5.72,19.32,5.12,28.66,1.02c2.28-1,4.42-2.28,6.45-3.75c4.82-1.67,9.37-3.73,12.9-5.17
	c8.81-3.61,17.41-7.13,25.52-12.2c2.65-1.66,5.24-3.43,7.77-5.29c27.24-14.02,57.78-31.7,57.7-66.12
	C634.44,211.78,624.37,189.87,615.45,169.02z"
      />
      <path
        fill="#FFFFFF"
        d="M186.33,338.59c1.28-3.17,2.4-6.4,3.3-9.69c1.07-3.9-0.65-8.12-4.14-10.16c-0.02-0.01-0.03-0.02-0.05-0.03
	c0.75-1.76,1.49-3.53,2.18-5.31c2.23-5.73-3.47-11.52-9.03-11.49c-0.12-4.24-2.74-8.43-7.26-9.09c-0.33-0.05-0.66-0.08-0.99-0.11
	c-2.46-0.93-5.34-0.7-7.94,0.51c-13.94,6.49-21.74,21.88-28.05,36c-10.5,11.03-17.92,24.03-23.61,38.21
	c-0.07,0.14-0.14,0.29-0.21,0.43c-4.15,8.84-6.98,17.76-8.56,27.34c-1.1,4.42-2.13,8.85-3.21,13.27c-2.11,1.23-3.68,3.4-3.82,6.52
	c-0.21,4.83-0.82,9.61-1.52,14.39c-1.42,4.63-2.41,9.43-3.03,14.32c-0.45,2.46-0.87,4.92-1.26,7.39c-0.87,3.56-1.29,7.2-1.39,10.87
	c-0.98,9.87-1.09,19.8,0.27,29.61c-2.42,17.34-0.13,35.52,4.52,52.09c5.15,18.36,15.01,40.07,34.79,46.04
	c1.55,0.47,2.96,0.46,4.21,0.12c6.7,4.15,15.68,4.41,19.56-1.92c1.05,0.89,2.15,1.73,3.32,2.5c7.93,5.22,15.52-6.06,9.69-12.56
	c-4.16-4.65-8.13-9.4-11.78-14.37c-1.51-3.03-2.82-6.11-4.09-8.84c-1.13-2.44-2.17-4.89-3.15-7.36c2.04-0.7,3.75-2.23,4.48-4.61
	c5.52,5.07,8.77,12.29,14.22,17.49c6.65,6.34,16.9-1.67,12.56-9.69c-10.55-19.51-19.45-39.51-23.71-60.81
	c0.2-5.95,0.04-11.92-0.41-17.87c4.59-21.29,10.67-42.24,19.97-61.78c4.12-7.54,8.75-14.81,12.65-22.46
	c2.32-4.54,0.38-8.65-2.86-10.63c3.99-9.75,7.7-19.52,8.96-30.41C191.31,343.45,189.15,340.23,186.33,338.59z"
      />
      <path
        fill="#FFFFFF"
        d="M379.74,495.37c-1.04,3.22-1.61,6.54-1.97,9.89c0.11-4.92,0.18-9.84,0.18-14.76c0.02-10.32-15.98-10.32-16,0
	c-0.01,4.78-0.07,9.55-0.17,14.33c-1.73,1.06-3.18,2.74-3.99,5.15c-0.02,0.07-0.04,0.14-0.07,0.2c-3.13-6.41-12.65-7.87-16.63-0.51
	c-1.74-1.08-3.81-1.62-5.85-1.54c-3.76-6.67-15.13-7.12-17.46,1.84c-0.24,0.91-0.45,1.82-0.68,2.73c-4.18-1.46-9.04,0.11-11.44,4.32
	c-5.14,0.59-9.44,2.57-12.48,6.4c-1.01-1.23-2.33-2.18-4.11-2.64c-7.88-2.02-12.18,7.12-8.77,12.35c0.06,0.25,0.12,0.5,0.18,0.76
	c0.43,1.8,0.74,3.64,1.06,5.46c0.88,5.08,1.57,10.22,2.18,14.91c1.53,11.78,2.86,23.6,4.05,35.42c1.65,16.46,4.01,32.82,6.52,49.17
	c1.02,6.68,2.31,13.21,3.72,19.81c0.14,0.68,0.28,1.36,0.42,2.04c-7.91,7.77-17.25,15.5-24.11,24.63
	c-6.04,2.02-11.1,7.1-13.46,13.05c-3.4,8.58,4.8,16.21,12.93,15.58c4.56,2.32,10.65,2.33,15.48,2.28c6.6-0.07,13.25-0.7,19.86-1.66
	c7.78,3.01,16.36,1.94,24.56-1.19c5.4,0.23,10.82,0.02,16.18-0.52c11.47-1.14,30.41-4.38,30.56-19.35c0.02-2.21-0.93-4.18-2.4-5.62
	c-0.01-0.02-0.02-0.03-0.02-0.05c3.58-11.31,4.34-23.22,4.54-35.18c0.27-16.24,1.18-32.27,2.6-48.45
	c1.56-17.74,2.89-35.51,4.54-53.24c0.84-9.08,1.68-18.17,2.52-27.25c0.74-7.93,0.46-16.46,2.93-24.09
	C398.36,489.78,382.91,485.6,379.74,495.37z"
      />
      <path
        fill="#FFFFFF"
        d="M589.58,601.19c-5.1-11.5-10.14-23.03-14.7-34.76c-4.74-12.19-8.66-24.58-14.05-36.52
	c-8.94-19.8-21.91-39.67-20.05-61.8c2.37-2.06,3.28-6.24,2.34-9.14c-0.43-1.32-0.57-2.68-1-4c-2.82-8.61-15.93-9.51-18.32,0
	c-0.24,0.96-0.46,1.93-0.66,2.9c-3.86,2.7-7.96,5.1-11.9,7.65c-4.08,2.64-7.98,5.57-11.82,8.57c-2.32-2.33-5.92-3.36-9.49-2.75
	c-7.12,1.21-13.98,7.46-19.24,11.92c-1.15,0.98-2.3,2-3.44,3.05c-0.07-3.2-0.72-6.27-2.59-9.11c-3.13-4.73-10.76-6.86-14.92-1.92
	c-6.53,7.75-8.39,17.41-8.31,27.18c-1.46,11.35,0.72,22.07,4.9,32.29c1.61,5.86,3.58,11.63,6,17.34
	c6.5,15.3,15.95,29.37,25.23,43.08c9.19,13.57,18.42,27.11,27.82,40.53c-0.22,0.47-0.91,2.01-0.85,1.88
	c-0.5,1.09-1.02,2.18-1.54,3.27c-1.14,2.41-2.29,4.81-3.41,7.23c-2.38,5.14-4.62,10.38-6.32,15.79
	c-5.32,10.29-7.96,23.26-0.03,32.84c8.37,10.12,20.66,5.27,31.43-1.66c6.11-2.2,11.77-6.95,16.59-11.98
	c9.87-7.05,19.5-15.21,27.82-24.47c1.86-1.57,3.71-3.15,5.57-4.71c6.03-5.09,15.17-10.17,20.31-18.13
	C601.97,624.88,594.08,611.35,589.58,601.19z"
      />
      <g>
        <g>
          <path
            d="M546.18,322.61c-1.73,0.62-3.49,1.18-5.25,1.67c-5.58,2.41-11.4,4.04-17.29,4.4c-4.81,1.26-9.82,1.66-14.54,0.62
			c-3.93-0.86-7.59-2.41-10.93-4.47c-0.04-0.02-0.08-0.03-0.12-0.06c-4.96-2.9-10.89-7.6-13.05-13.39
			c-1.83-0.89-3.36-1.69-3.56-2.04c-2.81-4.34-4.61-10.97-5.14-18.34c-2.18-4.67-5.26-10.27-4.29-15.28
			c-0.39-5.77,2.26-11.27,5.97-15.96c1.31-2.09,2.8-4.07,4.5-5.87c3.35-4.78,8-8.44,14.18-10.19c0.27-0.18,0.54-0.35,0.82-0.53
			c3.56-3.23,7.59-6.02,12.05-8.18c0.32-0.56,0.64-1.06,0.94-1.5l-31.25-90.54l-8.65,17.93l-15.61-10.44l-6.24,16.37l-15.32-9.41
			l-7.99,17.53c0,0-16.17-11.69-16.15-11.77c0.28-1.26-9.83,16.59-9.83,16.59l-18.67-9.29l-12.99,17.38l-18.66-9.29l-12.98,17.38
			l-18.66-9.29l-12.97,17.38l-18.66-9.29l-12.97,17.37l-18.66-9.29l-12.98,17.38l-18.66-9.29l-12.97,17.37l-18.66-9.29l-12.98,17.38
			L155,229.59l36.2,317.32l301.29-56.04l96.8-52.62L546.18,322.61z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M527.92,413.45l2.43-5.48c10.71,4.75,54.39,22.69,56.54,23.9l2.4,6.38
				C589.29,438.25,553.32,424.73,527.92,413.45z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M492.49,490.87c-22.53-75.46-85.83-318.94-85.53-324.5l2.3-3.2c0-0.07,3.82,2.82,3.82,2.82
				c1.01,8.41,62.41,232.19,88.62,320L492.49,490.87z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M532.55,411.04c-0.32-0.93-13.5-39.21-29.27-85.06c-3.14-0.57-5.74-2.31-7.94-4.65
				c15.4,44.78,28.85,83.86,31.18,90.64L498.2,487.5l8-4.25l26.69-71.19L532.55,411.04z"
            />
            <path
              fill="#FFFFFF"
              d="M479.91,257.97c-15.36-44.72-29.54-85.99-31.2-91.14l-8.79-5.37c1.92,5.42,19.95,56.61,36.04,103.47
				C476.56,261.87,477.75,259.13,479.91,257.97z"
            />
          </g>
        </g>
        <g>
          <path
            d="M512.95,333.52c-18.48,0-28.69-11.73-34.2-22.84c-6.37-12.83-15.21-41.02-3.12-55.9c8.84-10.89,69.8-39.59,76.72-42.83
			c1.5-0.7,3.29-0.05,3.99,1.45c0.7,1.5,0.05,3.29-1.45,3.99c-18.46,8.63-67.86,32.86-74.61,41.17
			c-9.37,11.53-2.62,36.45,3.84,49.45c8.52,17.16,22.77,23.03,42.34,17.44c19.09-5.45,67.6-30.83,85.88-46.4
			c20.47-17.43,24.56-50.52,15.54-70.48c-2.66-5.88-5.84-14.52-9.53-24.53c-8.16-22.13-20.48-55.57-28.85-58.92
			c-1.54-0.62-2.29-2.36-1.67-3.9c0.62-1.54,2.36-2.29,3.9-1.67c10.04,4.02,20.12,29.51,32.25,62.41c3.65,9.9,6.8,18.45,9.37,24.14
			c9.87,21.86,6.13,57.71-17.12,77.52c-18.75,15.97-68.53,42-88.12,47.6C522.52,332.81,517.49,333.52,512.95,333.52z"
          />
        </g>
        <g>
          <path
            d="M577.93,138.34c-0.18,0-0.36-0.01-0.52-0.02c-3.02-0.22-5.61-1.49-7.5-3.68c-4.13-4.79-3.68-12.37-3.61-13.21
			c0.13-1.65,1.57-2.88,3.22-2.76c1.65,0.13,2.88,1.56,2.76,3.21l0,0c0,0.03-0.2,2.89,0.6,5.68c1.15,4.04,3.38,4.66,4.95,4.77
			c0.83,0.06,1.75-0.6,2.37-1.16c4.48-4.06,8.48-15.93,8.75-25.93c0.21-8.08-1.27-13.46-4.29-15.56c-2.74-1.91-6.22-0.59-6.26-0.58
			c-1.54,0.62-3.28-0.13-3.9-1.66c-0.62-1.54,0.13-3.28,1.66-3.9c0.26-0.11,6.51-2.55,11.92,1.22c4.83,3.36,7.14,10.3,6.86,20.65
			c-0.27,10.32-4.36,24.45-10.72,30.21C581.82,137.82,579.55,138.34,577.93,138.34z"
          />
        </g>
        <g>
          <path
            d="M558.52,133.39c-2.09,0-4.21-0.59-6.35-1.76c-5.19-2.85-7.02-12.12-5.61-28.34c0.03-0.35,0.05-0.59,0.06-0.71
			c0-1.66,1.34-2.96,3-2.96c1.66,0,3,1.39,3,3.04c0,0.13-0.03,0.53-0.08,1.15c-1.71,19.63,2.09,22.32,2.53,22.56
			c1.81,1,3.43,1.26,4.96,0.79c2.32-0.7,4.61-3.08,6.6-6.89c3.4-6.48,6.85-26.02,7.67-34.24c0.36-3.6-3.61-7.97-8.18-9
			c-5.3-1.2-9.68,2.23-12.35,9.65c-0.56,1.56-2.28,2.37-3.84,1.81c-1.56-0.56-2.37-2.28-1.81-3.84
			c5.5-15.31,16.18-14.18,19.32-13.47c7.62,1.72,13.49,8.8,12.82,15.45c-0.75,7.47-4.2,28.55-8.33,36.43
			c-2.8,5.34-6.23,8.66-10.18,9.85C560.69,133.23,559.61,133.39,558.52,133.39z"
          />
        </g>
        <g>
          <path
            d="M553.62,217.67c-1.02,0-2.01-0.52-2.57-1.45c-0.89-1.48-21.87-36.41-28.23-52.8c-4.4-11.35-11.49-33.03-9.52-50.75
			c1.1-9.87,6.26-19.04,13.15-23.36c3.7-2.32,7.55-2.96,10.83-1.8c7.14,2.52,7.08,10.96,7.01,19.13c-0.01,0.79-0.01,1.58-0.01,2.37
			c0,8.19,0.33,18.47,0.33,18.57c0.05,1.66-1.25,3.04-2.9,3.1c-1.65,0.05-3.04-1.25-3.1-2.9c0-0.1-0.33-10.48-0.33-18.76
			c0-0.81,0.01-1.61,0.01-2.41c0.06-7.45-0.12-12.4-3.01-13.42c-1.52-0.54-3.53-0.1-5.65,1.23c-4.42,2.78-9.35,9.71-10.37,18.94
			c-1.83,16.45,4.95,37.08,9.15,47.92c6.17,15.91,27.56,51.51,27.77,51.87c0.85,1.42,0.4,3.26-1.02,4.12
			C554.68,217.53,554.15,217.67,553.62,217.67z"
          />
        </g>
        <g>
          <path
            d="M516.96,108c-0.82,0-1.64-0.34-2.23-1c-0.15-0.16-14.83-16.53-23.13-27.16c-5.69-7.28-4.57-15.04-0.52-18.93
			c3.19-3.08,9.34-4.62,16.16,1.13c6.02,5.08,13.79,11.79,19.4,16.67c-2-7.75-4.69-18.31-5.92-23.7
			c-2.33-10.16,2.19-16.88,7.85-18.28c5.41-1.33,12.14,2.12,14.98,12.84c4.57,17.31,10.18,31.87,10.23,32.02
			c0.6,1.55-0.17,3.28-1.72,3.88c-1.55,0.6-3.28-0.17-3.88-1.72c-0.06-0.15-5.78-15.03-10.44-32.65c-1.78-6.75-5.26-9.16-7.74-8.55
			c-2.74,0.68-4.88,4.8-3.44,11.11c2.06,9,8.25,32.67,8.31,32.91c0.33,1.27-0.2,2.62-1.32,3.31c-1.12,0.69-2.56,0.57-3.55-0.29
			c-0.16-0.14-16.1-14.09-26.63-22.96c-3.19-2.69-6.23-3.21-8.13-1.39c-2.06,1.98-2.37,6.48,1.09,10.92
			c8.17,10.46,22.72,26.68,22.87,26.84c1.11,1.23,1.01,3.13-0.23,4.24C518.39,107.75,517.67,108,516.96,108z"
          />
        </g>
        <g>
          <path
            d="M161.66,594.07c-2.1,0-4.73-0.45-8.01-1.57c-7.73-2.64-18.9-11.95-23.9-19.91c-7.55-12.01-10.3-26.44-10.41-27.05
			c-0.3-1.63,0.77-3.19,2.4-3.5c1.63-0.3,3.19,0.77,3.5,2.4c0.03,0.14,2.66,13.93,9.59,24.95c4.25,6.76,14.34,15.23,20.76,17.43
			c5.72,1.96,7.94,1.12,8.27,0.68c0.37-0.5,0.28-2.68-2.32-6.23c-3.39-4.61-6.31-9-11.2-17.82c-4.65-8.37-7.31-19.77-3.5-23.58
			c0.75-0.75,1.75-1.13,2.82-1.09c2.74,0.11,5.16,2.8,10.35,8.88c2.48,2.9,5.28,6.18,7.71,8.36c1.92,1.73,3.06,1.89,3.23,1.74
			c0.8-0.65,1.42-4.26-0.59-7.77c-2.17-3.8-4.21-7.96-6.58-12.79c-2.04-4.15-4.34-8.86-7.13-14.13
			c-6.43-12.14-7.39-46.86-1.94-70.09c6.38-27.19,26.55-58.62,27.4-59.94c0.9-1.39,2.76-1.79,4.15-0.89
			c1.39,0.9,1.79,2.76,0.89,4.15c-0.2,0.32-20.46,31.89-26.6,58.06c-5.54,23.61-3.86,55.98,1.4,65.91
			c2.84,5.36,5.16,10.11,7.22,14.3c2.32,4.73,4.32,8.82,6.4,12.45c3.18,5.56,2.8,12.48-0.85,15.42c-1.4,1.13-5.28,3.2-11.01-1.95
			c-2.72-2.44-5.66-5.88-8.26-8.92c-1.38-1.62-3.07-3.59-4.44-5.02c0.18,3.02,1.34,8.19,4.57,14c4.74,8.54,7.55,12.76,10.79,17.18
			c3.85,5.24,4.7,10.1,2.32,13.34C167.56,592.61,165.47,594.07,161.66,594.07z"
          />
        </g>
        <g>
          <path
            d="M142.81,595.79c-3.2,0-6.88-0.86-10.87-3.2c-14.02-8.2-24.61-36.6-25.05-37.8c-0.57-1.55,0.22-3.28,1.78-3.85
			c1.55-0.57,3.28,0.22,3.85,1.78c0.1,0.27,10.29,27.58,22.45,34.7c9.35,5.47,14.73-0.15,14.79-0.2c1.13-1.21,3.03-1.29,4.24-0.16
			c1.21,1.13,1.28,3.03,0.16,4.24C152.28,593.31,148.24,595.79,142.81,595.79z"
          />
        </g>
        <g>
          <path
            d="M140.62,597c-0.79,0-19.53-0.17-31.95-14.72c-10.48-12.28-22.83-29.62-25.37-54.98c-2.28-22.84-3.47-93.9,18.2-147.75
			c22.65-56.3,60.69-92.06,61.07-92.41c1.21-1.13,3.11-1.06,4.24,0.16c1.13,1.21,1.06,3.11-0.15,4.24
			c-0.37,0.35-37.46,35.25-59.59,90.26c-21.22,52.73-20.04,122.49-17.8,144.92c2.36,23.57,13.44,39.35,23.96,51.68
			c10.65,12.47,27.22,12.61,27.38,12.61c1.66,0,3,1.34,3,3S142.28,597,140.62,597z"
          />
        </g>
        <g>
          <path
            d="M270.18,224.87c-1.32,0-2.53-0.88-2.89-2.22l-28.59-105.81c-6.13-22.67-3.01-46.44,8.76-66.95
			c11.78-20.5,30.75-35.17,53.42-41.29c22.67-6.13,46.45-3.02,66.95,8.76c20.5,11.78,35.16,30.75,41.29,53.42l26.73,98.9
			c0.43,1.6-0.51,3.25-2.11,3.68c-1.6,0.43-3.25-0.51-3.68-2.11l-26.73-98.9c-5.71-21.12-19.38-38.8-38.49-49.78
			c-19.11-10.98-41.27-13.88-62.39-8.17c-21.12,5.71-38.8,19.38-49.78,38.49c-10.98,19.11-13.88,41.27-8.17,62.39l28.59,105.81
			c0.43,1.6-0.51,3.25-2.11,3.68C270.7,224.83,270.44,224.87,270.18,224.87z"
          />
        </g>
        <g>
          <path
            d="M207.02,240.24c-1.32,0-2.53-0.88-2.89-2.22l-28.59-105.81c-6.13-22.67-3.01-46.44,8.76-66.95
			c11.78-20.5,30.75-35.17,53.42-41.29c46.98-12.7,95.54,15.2,108.24,62.18l26.73,98.9c0.43,1.6-0.51,3.25-2.11,3.68
			c-1.6,0.43-3.25-0.51-3.68-2.11l-26.73-98.9c-11.83-43.79-57.09-69.79-100.88-57.95c-21.12,5.71-38.8,19.38-49.78,38.49
			c-10.98,19.11-13.88,41.27-8.17,62.39l28.59,105.81c0.43,1.6-0.51,3.25-2.11,3.68C207.54,240.21,207.28,240.24,207.02,240.24z"
          />
        </g>
        <g>
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="400.03,341.42 379.19,261.05 306.21,300.63 338.53,314.68 315.54,367.56 262.66,344.57
			223.43,434.81 252.61,447.49 279.15,386.43 332.03,409.43 367.71,327.37 		"
          />
        </g>
        <g>
          <path
            d="M301.6,720.56c-19.02,0-37.17-3.24-41.66-12.21c-4.43-8.86,8.73-21.77,21.46-34.25c4.81-4.72,10.25-10.05,11.05-12.05
			c1.8-8.53-16.59-134.84-18.1-142.45c-0.32-1.62,0.73-3.2,2.36-3.53c1.62-0.33,3.2,0.73,3.53,2.35
			c0.25,1.26,21.04,136.91,17.88,145.61c-1.12,3.08-5.27,7.24-12.51,14.35c-7.96,7.81-22.77,22.33-20.29,27.29
			c4.14,8.28,34.57,11.48,63.83,6.71c5.98-0.97,11.58-1.65,16.52-2.25c20.33-2.47,29.43-4.05,32.03-15.77
			c3.91-17.59,18.46-210.96,18.6-212.91c0.12-1.65,1.56-2.9,3.22-2.77c1.65,0.12,2.89,1.56,2.77,3.22
			c-0.6,7.98-14.73,195.76-18.73,213.76c-3.63,16.35-17.7,18.06-37.16,20.42c-4.89,0.59-10.42,1.27-16.28,2.22
			C321.56,719.69,311.46,720.56,301.6,720.56z"
          />
        </g>
        <g>
          <path
            d="M337.96,605.77c-4.03,0-8.2-0.13-12.52-0.4c-20.79-1.29-36.87-5.29-37.54-5.46c-1.61-0.4-2.58-2.03-2.18-3.64
			c0.4-1.61,2.03-2.58,3.64-2.18c0.64,0.16,64.2,15.76,98.69-5.64c1.41-0.87,3.26-0.44,4.13,0.97c0.87,1.41,0.44,3.26-0.97,4.13
			C378.1,601.68,360.23,605.77,337.96,605.77z"
          />
        </g>
        <g>
          <path
            d="M337.03,630.02c-3.43,0-6.96-0.1-10.58-0.31c-19.65-1.13-34.62-4.97-35.25-5.13c-1.6-0.42-2.57-2.05-2.15-3.66
			c0.42-1.6,2.05-2.56,3.66-2.15c0.59,0.15,59.52,15.07,94-6.31c1.41-0.87,3.26-0.44,4.13,0.97c0.87,1.41,0.44,3.26-0.97,4.13
			C376.51,625.84,358.77,630.02,337.03,630.02z"
          />
        </g>
        <g>
          <path
            d="M319.62,692.67c-0.75,0-1.51-0.28-2.09-0.85c-1.19-1.16-1.21-3.06-0.06-4.24c9.21-9.46,14.01-29.2,15.59-36.89
			c-11.83-1.09-37.17-3.44-38.69-3.74c-1.62-0.33-2.68-1.91-2.35-3.53c0.32-1.61,1.89-2.67,3.5-2.36
			c1.74,0.28,25.68,2.52,41.38,3.96l3.2,0.29l-0.51,3.17c-0.2,1.22-4.97,30.09-17.81,43.28
			C321.18,692.36,320.4,692.67,319.62,692.67z"
          />
        </g>
        <g>
          <path
            d="M322.21,668.25c-0.21,0-0.42-0.02-0.64-0.07l-25.5-5.5c-1.62-0.35-2.65-1.95-2.3-3.56c0.35-1.62,1.95-2.65,3.57-2.3
			l25.5,5.5c1.62,0.35,2.65,1.95,2.3,3.56C324.83,667.29,323.59,668.25,322.21,668.25z"
          />
        </g>
        <g>
          <path
            d="M313.45,681.75c-0.35,0-0.72-0.06-1.07-0.2l-23-8.75c-1.55-0.59-2.33-2.32-1.74-3.87c0.59-1.55,2.32-2.33,3.87-1.74
			l23,8.75c1.55,0.59,2.33,2.32,1.74,3.87C315.8,681.01,314.66,681.75,313.45,681.75z"
          />
        </g>
        <g>
          <path
            d="M303.45,693c-0.47,0-0.94-0.11-1.38-0.34l-20.25-10.5c-1.47-0.76-2.04-2.57-1.28-4.04c0.76-1.47,2.57-2.04,4.04-1.28
			l20.25,10.5c1.47,0.76,2.04,2.57,1.28,4.04C305.58,692.41,304.54,693,303.45,693z"
          />
        </g>
        <g>
          <path
            d="M310.86,712.46c-27.74,0-46.78-11.56-47.74-12.16c-1.41-0.87-1.84-2.72-0.97-4.13c0.87-1.41,2.72-1.84,4.13-0.97l0,0
			c0.27,0.16,26.96,16.29,62.11,9.6c38.89-7.4,49.98-18.13,50.09-18.24c1.16-1.16,3.06-1.18,4.23-0.03
			c1.17,1.15,1.21,3.03,0.07,4.21c-0.47,0.49-12.05,12.11-53.27,19.95C322.97,711.94,316.72,712.46,310.86,712.46z"
          />
        </g>
        <g>
          <g>
            <path
              d="M508.96,705.35c-4.71,0-9.28-0.87-13.26-2.64c-6.19-2.76-10.2-7.46-11.28-13.24c-2.45-13.04,4.23-27.46,9.6-39.06
				c3.38-7.31,6.58-14.2,5.97-17.68c-0.77-4.39-18.59-31.45-34.31-55.33c-5.65-8.58-11.5-17.45-17.23-26.27
				c-12.47-19.17-12.99-56.8-13.22-72.87c-0.03-2.27-0.06-4.62-0.14-5.38c-0.34-1.1-0.02-2.35,0.9-3.15
				c1.25-1.08,3.15-0.95,4.23,0.3c0.9,1.04,0.91,1.44,1,8.15c0.2,14.21,0.73,51.97,12.25,69.68c5.72,8.8,11.56,17.66,17.21,26.24
				c21.86,33.19,34.27,52.24,35.21,57.59c0.93,5.33-2.32,12.35-6.43,21.24c-5.01,10.82-11.24,24.28-9.14,35.43
				c0.91,4.86,4.76,7.5,7.83,8.87c8.14,3.62,19.84,2.49,28.45-2.77c11.74-7.16,65.04-52.17,69.12-65.06
				c0.66-2.08-0.75-12.85-10.8-38.67c-8.76-22.49-20.99-48.28-33.57-70.76c-16.73-29.91-14-88.63-13.88-91.11
				c0.08-1.65,1.49-2.92,3.15-2.85c1.65,0.08,2.93,1.49,2.85,3.15c-0.03,0.59-2.76,59.49,13.12,87.89
				c26.11,46.68,48.33,103.23,44.86,114.18c-4.85,15.3-60.94,61.8-71.72,68.37C523.48,703.38,516.06,705.35,508.96,705.35z"
            />
          </g>
          <g>
            <path
              d="M467.84,575.39c-0.2,0-0.33,0-0.38,0c-1.66-0.02-2.98-1.38-2.96-3.04c0.02-1.64,1.36-2.96,3-2.96c0.03,0,0.02,0,0.16,0
				c3.08,0,61.57-0.61,87.24-41.97c0.87-1.41,2.72-1.84,4.13-0.97c1.41,0.87,1.84,2.72,0.97,4.13
				c-11.63,18.75-31.35,32.44-57.02,39.6C485.14,575.16,470.41,575.39,467.84,575.39z"
            />
          </g>
          <g>
            <path
              d="M489.44,600.76c-2.16,0-3.45-0.08-3.62-0.09c-1.65-0.1-2.91-1.53-2.8-3.18c0.1-1.65,1.53-2.91,3.18-2.81
				c0.58,0.03,57.8,3.06,82.53-39.9c0.83-1.44,2.66-1.93,4.1-1.1c1.44,0.83,1.93,2.66,1.1,4.1c-11.04,19.17-29.8,32.67-54.25,39.03
				C506.46,600.25,495.03,600.76,489.44,600.76z"
            />
          </g>
          <g>
            <path
              d="M509.99,695.13c-4.66,0-8.69-1.01-11.89-2.24c-7.43-2.87-11.9-7.49-12.09-7.69c-1.14-1.2-1.1-3.1,0.1-4.24
				c1.2-1.14,3.09-1.1,4.24,0.09c0.62,0.64,15.14,15.28,35.04,3.56c40.5-23.85,67.32-70.77,67.59-71.24
				c0.81-1.44,2.65-1.95,4.09-1.14c1.44,0.81,1.95,2.64,1.14,4.09c-1.12,1.98-27.9,48.8-69.77,73.46
				C521.68,693.76,515.41,695.13,509.99,695.13z"
            />
          </g>
          <g>
            <path
              d="M535.34,658.84c-0.63,0-1.26-0.2-1.8-0.6c-1.33-0.99-1.59-2.87-0.6-4.2c3.08-4.1,3.67-13.75,1.66-27.17
				c-1.08-7.23-2.66-13.85-3.58-17.43c-12.25,2.91-34.68,8.21-35.94,8.37c-1.64,0.21-3.14-0.96-3.35-2.6
				c-0.2-1.63,0.93-3.11,2.55-3.35c1.63-0.29,23.71-5.49,38.19-8.93l2.78-0.66l0.79,2.75c1.17,4.06,11.15,40,1.69,52.62
				C537.16,658.42,536.26,658.84,535.34,658.84z"
            />
          </g>
          <g>
            <path
              d="M502.93,635.21c-1.43,0-2.7-1.03-2.95-2.49c-0.28-1.63,0.81-3.19,2.44-3.47l25.43-4.41c1.63-0.28,3.19,0.81,3.47,2.44
				c0.28,1.63-0.81,3.19-2.44,3.47l-25.43,4.41C503.27,635.2,503.1,635.21,502.93,635.21z"
            />
          </g>
          <g>
            <path
              d="M528.18,648.38c-0.03,0-0.06,0-0.09,0c-11.6-0.34-24.76-0.59-26.78-0.39c-1.35,0.33-2.79-0.32-3.42-1.62
				c-0.72-1.49-0.09-3.29,1.41-4c0.8-0.38,1.63-0.78,28.96,0.02c1.66,0.05,2.96,1.43,2.91,3.09
				C531.13,647.09,529.79,648.38,528.18,648.38z"
            />
          </g>
          <g>
            <path
              d="M522.86,663.51c-0.14,0-0.28-0.01-0.43-0.03l-25.59-3.66c-1.64-0.23-2.78-1.75-2.54-3.39c0.23-1.64,1.75-2.78,3.39-2.55
				l25.59,3.66c1.64,0.23,2.78,1.75,2.54,3.39C525.61,662.43,524.33,663.51,522.86,663.51z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
