import { h } from 'preact';
import { SvgUse } from '..';

const attributes = {
  width: 659.06,
  height: 454.14,
  id: 'work-and-money-brand-logo'
};

export const WorkAndMoneyBrandLogo = () => (
  <symbol {...attributes}>
    <g>
      <path fill="#07184f" d="M185.49,160.63c0,3.94,2.37,4.73,5.53,4.73,16.18,0,26.44-4.34,32.75-4.34,10.66,0,11.84,8.29,11.84,18.16,0,11.44-6.31,40.25-13.81,52.09-4.34,6.71-10.26,9.47-17.76,9.47H190.62c-3.55,0-5.14.82-5.52,3.95-3.46,28.39,6,80.29,21.54,134.38,3.46,12,3.22,20.69-7.06,30.15-10.94,10.06-31.82,22.86-41.84,22.59-13.74-.38-24.29-16.71-31.24-45.08-11.09-45.32-13.63-139.67-13.63-139.67,0-3.55-1.18-6.32-7.89-6.32H58.8c-13.81,0-24.46-3.55-29.2-6.7C13,224.17,0,204.44,0,189.83c0-10.26,3.16-21.7,43.41-71.43C81.69,71,154.31,0,174.44,0c11.84,0,17.76,6.71,17.76,18.94C192.2,33.55,185.49,133.79,185.49,160.63ZM75.78,166.55h32.36c5.92,0,7.5-3.16,7.5-7.11,1.58-28.41,3.15-37.88,3.15-43,0-5.92-.39-8.29-2.36-8.29-5.53,0-22.11,17.76-33.55,31.57C74.59,150,68.28,157.87,68.28,163,68.28,165,69.86,166.55,75.78,166.55Z"/>
      <path fill="#07184f" d="M366.11,279.82c-81.7,0-115.64-61.57-115.64-130.64,0-81.69,44.6-148,117.61-148,71.83,0,115.24,63.94,115.24,145.63C483.32,222.59,445.83,279.82,366.11,279.82ZM386.63,79.33c-7.11-18.55-16.58-29.21-24.08-29.21-11.05,0-23.28,22.1-23.28,74.59,0,19,.79,57.23,11.05,80.91,5.13,11.84,13.81,20.13,24.47,20.13,16.57,0,25.26-20.13,25.26-59.6C400.05,130.63,394.52,100.24,386.63,79.33Z"/>
      <path fill="#07184f" d="M622,16.58c0,24.46-4.73,65.9-4.73,114.84,0,47.76,1.18,69.07,3.55,83.28,4.34,27.23,38.28,23.28,38.28,45.78,0,8.28-6.31,15.78-18.15,15.78-17,0-45.39-1.18-62-1.18-17,0-54.86,1.18-71,1.18-12.24,0-18.55-7.5-18.55-15.78,0-22.1,39.86-17.76,44.2-45.78,2.37-14.21,3.16-35.52,3.16-83.28,0-19.73-.79-41.44-9.87-47.75-6.31-4.34-8.29-5.13-20.52-5.13-11.84,0-15-7.11-15-12.63,0-10.26,6.32-14.6,17.37-19.34,52.49-22.1,86.43-45,97.08-45C617.62,1.58,622,7.89,622,16.58Z"/>
      <path fill="#00ca5c" d="M318.58,320.83c32,0,59.14,22.33,59.14,63.57,0,38.52-25.74,58.12-58.29,58.12-38,0-59.82-25-59.82-59.82C259.61,346.06,283,320.83,318.58,320.83Zm-15,45.17c0,13.46,2.39,28.8,7.33,40.56,3.41,7.84,8.18,13.63,14.48,13.63,6,0,11.59-4.94,11.59-20.79,0-12.27-3.24-29.14-8.35-41.07-4.26-11.25-10.56-17.73-15.68-17.73C309,340.6,303.58,345.38,303.58,366Z"/>
      <path fill="#00ca5c" d="M440.52,442.2c3.5,3.18,6.21,4.77,6.21,8,0,2.87-2.07,4-6.21,4-11,0-24.37-8.12-33.76-21.18s-14-30.26-14-50.81c0-19.58,4.14-37.58,14.18-51.75,9.87-13.86,22.93-21.19,33.6-21.19,4.14,0,6.21,1.12,6.21,4,0,3.18-2.71,4.77-6.21,8-14.49,13.38-18.47,39.18-18.47,59.56C422.05,399,425.23,428,440.52,442.2Z"/>
      <path fill="#00ca5c" d="M520.62,342.65c0-5.45-5.62-7.67-5.62-13.29,0-3.41,2.9-6.14,8.52-6.14,4.6,0,11.42.51,16.36.51,4.77,0,9.89-.51,14.49-.51,7.16,0,11.76.34,11.76,6.65,0,5.62-7,6.81-13.64,12.1-8.35,6.64-16,13.63-26.58,27.27-.35.51-1,1.19-1,1.87,0,.34.17.68,1,1,16.53,6.14,24.37,22.84,30.33,38.52,2.73,7.16,6.31,12.78,10.06,16.36,3.24,3.07,5.11,3.41,5.11,7.16,0,4.77-3.07,6-6.31,6-3.92,0-16.36-.51-20.62-.51-8.18,0-17,.51-20.11.51-5.45,0-8.86-2.89-8.86-6.64,0-3.41,1.87-5.29,1.87-9.38,0-9.54-8.69-28.12-16.53-28.12-2.55,0-4.26,2.39-4.26,8.87v.85c0,8.86,2.56,13.46,5.12,17.72s6.47,6.48,6.47,10.74c0,4.09-3.41,6-7.84,6-8.35,0-14.83-.51-21.81-.51-7.33,0-14.66.51-21.65.51-5.28,0-8-2.72-8-6,0-7.16,8.35-7.5,10.23-19.77.85-6,1.19-16.53,1.19-36.81,0-14.32,0-18.75-.34-23.52-1.53-17.05-10.23-16.53-10.23-24.37,0-3.75,1.88-6.48,7.16-6.48,7.33,0,14.66.51,21.82.51,7.33,0,14.65-.51,21.47-.51,5.11,0,7.16,2.73,7.16,6.48,0,5.11-4.77,8.52-7.33,13.46-1.7,3.24-3.92,7.67-3.92,16.19,0,9.89.34,14.66,1.36,14.66C499.83,374,520.62,349.64,520.62,342.65Z"/>
      <path fill="#00ca5c" d="M577.07,321.16c-3.5-3.19-6.21-4.78-6.21-8,0-2.87,2.07-4,6.21-4,11,0,24.37,8.13,33.77,21.19s14,30.25,14,50.8c0,19.59-4.14,37.58-14.17,51.76-9.88,13.85-22.94,21.18-33.61,21.18-4.14,0-6.21-1.11-6.21-4,0-3.19,2.71-4.78,6.21-8,14.5-13.38,18.48-39.18,18.48-59.57C595.55,364.32,592.36,335.33,577.07,321.16Z"/>
    </g>
  </symbol>
);

export const UseWorkAndMoneyBrandLogo = ({...props}) => (
  <SvgUse {...attributes} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
