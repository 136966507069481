import storage from '@buzzfeed/bf-utils/lib/storage';
const STORAGE_KEY = 'user_temp_wishlist';
const DEFAULT_VALUE = {
  links: [],
  preview: [],
  tooltips: [],
  abtests: {}
};

function getLocalData() {
  const storageValue = storage.get(STORAGE_KEY);
  if (storageValue && validateLocalData(JSON.parse(storageValue))) {
    const localData = JSON.parse(storageValue);
    return localData;
  } else {
    return DEFAULT_VALUE;
  }
}

function validateLocalData(storageValue) {
  if (typeof storageValue === 'object') {
    if ('links' in storageValue && 'tooltips' in storageValue) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function hasFlag(flag) {
  let wishlist = getLocalData();
  return wishlist['tooltips'].includes(flag);
}

function addFlag(flag) {
  let wishlist = getLocalData();

  if (!wishlist['tooltips'].includes(flag)) {
    wishlist['tooltips'].push(flag);
  }
  saveLocalData(wishlist);
}

function removeFlag(flag) {
  let wishlist = getLocalData();

  if (wishlist['tooltips'].includes(flag)) {
    wishlist['tooltips'] = wishlist['tooltips'].filter(e => e !== flag);
  }
  saveLocalData(wishlist);
}

function saveLocalData(wishlist) {
  storage.set({
    key: STORAGE_KEY,
    value: JSON.stringify(wishlist)
  });
}

function get() {
  return getLocalData();
}

function update({key, value}) {
  let wishlist = getLocalData();
  wishlist[key] = value;
  saveLocalData(wishlist);
}

export {
  get,
  hasFlag,
  addFlag,
  removeFlag,
  update
};
