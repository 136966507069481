import { h } from 'preact';
import { SvgUse } from '..';

const attributes = {
  width: 2417,
  height: 1167,
  id: 'nifty-brand-logo'
};

export const NiftyBrandLogo = () => (
  <symbol {...attributes}>
    <g fill="#0031bc">
      <path d="m1811.17 150.917 214.291 295.125v229.333l171.459-40.542v-232.625l213.416-393.875-197.25 46.917-102.625 214.875-104.375-165.625z"/><path d="m1798.12 154.042-478.5 114.125v150.125l152.833-36.375v424.833l173.125-40.917v-424.875l152.542-36.541z"/><path d="m1285.04 426.542v-150.25l-433.834 102.75v575.875l173.167-40.917v-216.208l254.042-60.042v-152.417l-254.042 60.5v-56.916z"/><path d="m618.125 1010.38 173.125-40.917v-575.833l-173.125 40.917z"/><path d="m8.333 578.875v575.833l171.792-40.625v-290.75l211.625 240.75 166.458-39.333v-575.875l-173.083 40.917v271.833l-199.167-224.75z"/>
    </g>
  </symbol>
);

export const UseNiftyBrandLogo = ({...props}) => (
  <SvgUse id={attributes.id} viewBox={`0 0 ${attributes.width} ${attributes.height}`} {...props} />
);
