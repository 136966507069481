import {
  urlToId,
  cleanLink
} from '../../../utils/url';
import cookies from '@buzzfeed/bf-utils/lib/cookies';
import {
  createClientImpressionHandler,
} from '@buzzfeed/client-event-tracking/dist/lib/handlers';

const clientEventTracking = async () =>
  import(/* webpackChunkName: "clientEventTrackingSetup" */ '../../shared/tracking/clientEventTrackingSetup');

const EXTERNAL_LINK_LABELS = ['bfnews', 'tasty', 'wishlist'];
const UNIT = {
  unit_type: 'nav',
  unit_name: 'main',
};

export const trackNavClick = ({
  label,
  location,
  url,
  isInfoPage,
  trackingData = {}
}) => {
  const buzzLayer = getBuzzLayer();
  if (buzzLayer === undefined) {
    return;
  }

  const baseWebContentData = {
    ...UNIT,
    position_in_unit: '',
    item_type: 'text',
    item_name: urlToId(url) || label.toLowerCase(),
  };

  const eventWebContentData = {
    badgeBar: {
      ...baseWebContentData,
      item_type: 'badge'
    },
    logo: {
      ...baseWebContentData,
      item_name: label
    },
    trendingBar: {
      ...baseWebContentData,
      unit_name: 'topic_nav'
    },
    moreMenu: {
      ...baseWebContentData,
      unit_name: 'hidden'
    },
    userMenu: {
      ...baseWebContentData,
      unit_name: 'profile_nav'
    }
  };

  let webContentData = eventWebContentData[location] || baseWebContentData;

  let targetContentType;
  let targetContentId;

  if (location === 'userMenu') {
    if (label === 'notification-link') {
      webContentData['item_type'] = 'notification';
      webContentData['item_name'] = trackingData['item_name'] || '';
      targetContentType = ''; //todo
      targetContentId = ''; //todo
    } else if (label === 'teaser-notification-link') {
      webContentData['item_type'] = 'teaser_notification';
      webContentData['item_name'] = trackingData['item_name'] || '';
      targetContentType = ''; //todo
      targetContentId = ''; //todo
    } else if (label === 'profile') {
      webContentData['item_type'] = 'profile';
      webContentData['item_name'] = 'icon';
      targetContentId = cookies.get('user_uuid') || 'unauthenticated';
      targetContentType = 'user';
    } else {
      webContentData['item_type'] = 'text';
      targetContentType = 'url';
      targetContentId = url;
      webContentData['item_name'] = label
        .toLowerCase()
        .replace(/ /g, '_');
    }
  }

  if (isInfoPage) {
    targetContentType = 'info';
  }

  if (location === 'logo') {
    targetContentId = label;
  }

  if (label === 'merch') {
    webContentData.item_name = 'merch';
    targetContentId = 'shop.buzzfeed.com';
  }

  if (location === 'wishlistPreview') {
    webContentData.unit_name = 'wishlistPreview';
    webContentData.item_type = 'button';
  }

  if (label === 'my_wishlist') {
    webContentData.item_name = label;
    targetContentType = 'wishlist';
    targetContentId = cookies.get('user_uuid') || 'unauthenticated';
  }

  if (location === 'wishlist') {
    webContentData.unit_name = 'wishlist';
  }

  if (EXTERNAL_LINK_LABELS.includes(label.toLowerCase())) {
    webContentData.item_name = label.toLowerCase();

    trackClientEventExternalLink({
      linkData: {
        click_type: 'left',
        ...webContentData,
        target_content_url: cleanLink(url),
      },
      layers: [buzzLayer()],
    });
  } else {
    trackClientEventInternalLink({
      linkData: {
        click_type: 'left',
        ...webContentData,
        target_content_type: targetContentType || 'feed',
        target_content_id: targetContentId || urlToId(url) || cleanLink(url),
        ...trackingData
      },
      layers: [buzzLayer()],
    });
  }
};

export const trackNavAction = ({ location, action_value }) => {

  const buzzLayer = getBuzzLayer();
  const eventWebContentData = {
    search: {
      ...UNIT,
      item_type: 'submission',
      item_name: 'search',
      position_in_unit: 7,
      action_type: 'search',
    },
    ccpa: {
      ...UNIT,
      unit_name: 'hidden',
      item_type: 'button',
      item_name: 'ccpa_button',
      action_type: 'show',
    },
    notificationMenu: {
      ...UNIT,
      item_type: 'button',
      item_name: 'notification_menu',
      action_type: 'show',
    },
    teaserNotificationMenu: {
      ...UNIT,
      item_type: 'button',
      item_name: 'teaser_notification_menu',
      action_type: 'show',
    },
    wishlistTeaser: {
      ...UNIT,
      item_type: 'button',
      item_name: 'wishlist_teaser',
      action_type: 'show',
    },
    wishlistPreview: {
      ...UNIT,
      item_type: 'button',
      item_name: 'wishlist_preview',
      action_type: 'show',
    },
    wishlistSignIn: {
      ...UNIT,
      item_type: 'button',
      item_name: 'wishlist_signin',
      action_type: 'show',
    }
  };

  if (buzzLayer === undefined || !eventWebContentData[location]) {
    return;
  }

  trackClientEventContentAction({
    layers: [
      eventWebContentData[location],
      {
        subunit_type: '',
        subunit_name: '',
        action_value: action_value,
      },
      buzzLayer(),
    ],
  });
};

export const trackNavExperiments = (experiments) => {
  const buzzLayer = getBuzzLayer();
  if (buzzLayer === undefined) {
    return;
  }

  const experiment_id = [];
  Object.keys(experiments.eligible).forEach(key => {
    const experiment = experiments.eligible[key];
    if (experiment && experiment.value) {
      experiment_id.push(
        [
          key,
          experiment.id,
          experiment.version,
          experiment.value,
          experiment.variant_id,
        ].join('|')
      );
    }
  });

  trackClientExperimentActive({
    layers: [buzzLayer()],
    experiment_id
  });
};

async function trackClientEventInternalLink({ linkData, layers }) {
  const { internalClick } = await clientEventTracking();
  internalClick(linkData, ...layers);
}

async function trackClientEventExternalLink({ linkData, layers }) {
  const { externalClick } = await clientEventTracking();
  externalClick(linkData, ...layers);
}

export async function trackClientEventContentAction({ layers }) {
  const { contentAction } = await clientEventTracking();
  contentAction(...layers);
}

async function trackClientExperimentActive({ layers, experiment_id }) {
  const { abTest } = await clientEventTracking();
  abTest(...layers, { experiment_id });
}

export async function trackClientEventImpression({trackingData = {}}) {
  const buzzLayer = getBuzzLayer();
  if (buzzLayer === undefined) {
    return;
  }
  const { impression } = await clientEventTracking();
  impression({
    ...UNIT,
    ...trackingData
  }, buzzLayer());
}
export const createImpressionHandler = () => createClientImpressionHandler(trackClientEventImpression);
// unable create `attachClientImpressionHandler` because tests fail with error
// `ReferenceError: IntersectionObserver is not defined`
// export const attachClientImpressionHandler = createClientImpressionHandler(trackClientEventImpression);

function getBuzzLayer() {
  if (typeof window === 'undefined'
    || !window.clientEventTracking
    || typeof window.clientEventTracking.getPageContextLayer !== 'function'
  ) {
    return undefined;
  }

  return window.clientEventTracking.getPageContextLayer();
}
