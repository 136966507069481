import { getUserInfo } from '@buzzfeed/bf-utils/lib/user';
import { h, Component } from 'preact';
import { get as getAPI, add as addAPI, remove as removeAPI, getItemsByLinkIds } from './api';
import { get as getLocal, update as updateLocal } from './localstorage';
const EDITIONS = ['us'];

class WishlistHandler extends Component {
  constructor(props) {

    super(props);

    this.state = {
      inited: false,
      items: [], // wishlist items from api
      links: [], // linkIds from localstorage
      preview: [], // preview links/items data
      url: false,
    };
    this.enabled = EDITIONS.includes(props.edition);

    if (this.enabled) {
      this.userId = getUserInfo() ? getUserInfo().userid : false;
      this.init();
    }
  }

  wishlistListener(e) {
    if (!e.detail || !e.detail.action) {
      return;
    }

    if (e.detail.action === 'getWishlist') {
      this.sendWishlistData();
    } else if (e.detail.ids) {
      switch (e.detail.action) {
      case 'add':
        this.add(e.detail.ids, e.detail.initiator);
        break;
      case 'remove':
        this.remove(e.detail.ids, e.detail.initiator);
        break;
      case 'getLinksData':
        this.getLinksData(e.detail.ids, e.detail.initiator);
        break;
      }
    }
  }

  sendData(data) {
    const event = new CustomEvent('wishlistHandler', {
      detail: data
    });
    window.dispatchEvent(event);
  }

  sendWishlistData() {
    if (!this.state.inited) {
      return;
    }

    const items = this.userId ? this.state.items : this.state.links;

    this.sendData({
      action: 'getWishlist',
      items: items,
      preview: this.state.preview,
      url: this.state.url
    });
  }

  add(linkIds, initiator) {
    // fire to animate wishlist header icon
    this.sendData({
      initiator,
      action: 'add',
      linkIds
    });

    if (this.userId) {
      addAPI({ linkIds }).then((res) => {
        if (!res.length) {
          return;
        }

        const linkIdsToFetch = res.map(item => item.link_id);
        getItemsByLinkIds({ linkIds: linkIdsToFetch }).then(resp => {
          let newPreviewItems = [];

          if (resp && resp.results && resp.results.items) {
            newPreviewItems = this.filterPreviewItems(resp.results.items.map(item => ({
              ...item,
              // eslint-disable-next-line camelcase
              link_id: item.affiliate_link_id
            })));
          }

          this.sendData({
            initiator,
            action: 'added',
            items: res,
            preview: newPreviewItems
          });

          this.setState(prevState => {
            return {
              ...prevState,
              items: [...res, ...prevState.items],
              preview: [...newPreviewItems, ...prevState.preview]
            };
          });
        });
      });
    } else {
      this.setState((prevState) => {
        // eslint-disable-next-line max-nested-callbacks
        const newLinkItems = linkIds.filter(linkId => !prevState.links.find(link => link.link_id === linkId))
          .map(linkId => ({
            id: linkId,
            // eslint-disable-next-line camelcase
            link_id: linkId
          }));
        this.sendData({
          initiator,
          action: 'added',
          items: newLinkItems,
        });

        return {
          ...prevState,
          links: [...newLinkItems, ...prevState.links]
        };
      });
    }
  }

  remove(linkIds, initiator) {
    const storageName = this.userId ? 'items' : 'links';
    const itemsToRemove = this.state[storageName]
      .filter(item => linkIds.indexOf(item.link_id) !== -1);
    const leftItems = this.state[storageName]
      .filter(item => linkIds.indexOf(item.link_id) === -1);
    if (this.userId) {
      const idsToRemove = itemsToRemove.map(item => item.id);
      removeAPI({ids: idsToRemove}).then(() => {
        this.sendData({
          initiator,
          action: 'removed',
          items: itemsToRemove,
        });
      });
    } else {
      this.sendData({
        initiator,
        action: 'removed',
        items: itemsToRemove,
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      [storageName]: [...leftItems]
    }));
  }

  getLinksData(linkIds, initiator) {
    getItemsByLinkIds({ linkIds }).then(resp => {
      this.sendData({
        initiator,
        action: 'getLinksData',
        linksData: resp?.results?.items || []
      });
    });
  }

  filterPreviewItems(items) {
    return items.filter(item =>
      item.product_data &&
      item.product_data.title &&
      item.product_data.img_url
    );
  }

  fetchWishlistData() {
    // fetch all wishlist items from api
    getAPI({userId: this.userId}).then(res => {
      if (!res) {
        return;
      }
      const items = res[0]?.items || [];
      this.setState((prevState) => ({
        ...prevState,
        items,
        url: `/wishlists/${getUserInfo().username}/${res[0].id}`,
        inited: true,
        preview: this.filterPreviewItems(this.convertLocalData(getLocal()).preview) // copy preview items to reduce count of requests
      }));
      // send to api local saved links
      if (this.state.links.length) {
        const localLinkIds = this.state.links
          // eslint-disable-next-line max-nested-callbacks
          .filter(link => !items.find(item => item.link_id === link.link_id))
          .map(item => item.link_id);
        if (localLinkIds.length) {
          this.add(localLinkIds);
        }

        this.setState((prevState) => ({
          ...prevState,
          links: []
        }));
      }
    });
  }

  updatePreviewData() {
    if (!this.state.inited) {
      return;
    }
    const links = (this.userId ? this.state.items : this.state.links) || [];
    const preview = this.state.preview || [];
    const linkIdsToFetch = links.filter(link => {
      return !preview.find(item => item.link_id === link.link_id);
    }).map(link => link.link_id);
    if (linkIdsToFetch.length) {
      getItemsByLinkIds({ linkIds: linkIdsToFetch }).then(resp => {
        let newPreviewItems = [];
        if (resp && resp.results && resp.results.items) {
          newPreviewItems = this.filterPreviewItems(resp.results.items).map(item => ({
            ...item,
            // eslint-disable-next-line camelcase
            link_id: item.affiliate_link_id
          }));
        }
        this.setState(prevState => {
          // eslint-disable-next-line max-nested-callbacks
          const oldPreviewItems = preview.filter(item => links.find(link => link.link_id === item.link_id));
          return {
            ...prevState,
            preview: [...newPreviewItems, ...oldPreviewItems]
          };
        });
      });
    } else {
      const leftPreviewItems = preview
        .filter(item => links.find(link => link.link_id === item.link_id));
      this.setState(prevState => {
        return {
          ...prevState,
          preview: [...leftPreviewItems]
        };
      });
    }
  }
  convertLocalData = (data) => {
    return {
      ...data,
      items: [],
      preview: data.preview || [],
      links: data.links ? data.links.map(link => ({
        id: link,
        // eslint-disable-next-line camelcase
        link_id: link
      })) : []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.items !== this.state.items && this.userId) {
      this.updatePreviewData(); // update preview items
    }
    if (prevState.links !== this.state.links) {
      updateLocal({
        key: 'links',
        value: this.state.links.map(link => link.link_id) // convert links items to array of linkIDs
      });
      this.updatePreviewData(); // update preview items
    }
    // update preview items in localstorage
    if (prevState.preview !== this.state.preview) {
      updateLocal({
        key: 'preview',
        value: this.state.preview
      });
    }
    this.sendWishlistData();
  }
  componentDidMount() {
    window.addEventListener('wishlistListener', this.wishlistListener.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('wishlistListener', this.wishlistListener.bind(this));
  }

  init() {
    if (this.state.inited) {
      return;
    }
    if (this.userId) {
      this.fetchWishlistData();
    } else {
      this.setState((prevState) => ({
        ...prevState,
        ...this.convertLocalData(getLocal()),
        inited: true,
      }));
      this.updatePreviewData();
    }
  }

  render() {
    return (
      <span />
    );
  }
}

export default WishlistHandler;
