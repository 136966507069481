import { PASSTHROUGH_KEY, STREAK_TROPHY_ID } from './returnVisits';
const TEASER_PASSTHROUGH_TYPES = ['trophy_earned'];

/**
 * =========================================
 * TEASER NOTIFICATIONS FOR LOGGED-OUT USERS
 * =========================================
 *
 * Notifications are generally for logged-in users, but we used them to show
 * unauthenticated users what they're missing.
 *
 * Teaser notifications are triggered by reading from the user_auth_passthrough
 * entry in localStorage. If there is an entry that we have decided triggers a
 * teaser notification, we set the state of the NotifUserMenu to contain a
 * notification based on that trigger. The logic for what causes teaser noti-
 * fications lives in this file. Currently, only earning the homepage view
 * streak trophy while logged out causes a teaser notification.
 *
 * getTeaserNotifications is the main function here. It collects up noti-
 * fications from fetching functions per type. The only fetching function now
 * is getStreakTrophies.
 *
 * These teaser notifications masquerade as real notifications once they're in
 * the notifications components. To see where the switch happens, look at the
 * updateNotifications method of NotifUserMenu.
 *
 * To see where the trophy action comes from in localStorage, see the comment
 * in returnVisits.js.
 */
export function getLocalStorageItem(key) {
  const value = window.localStorage.getItem(key);

  return value ? JSON.parse(value) : null;
}

export function getStreakTrophies() {
  const userAuthPassthrough = getLocalStorageItem(PASSTHROUGH_KEY);
  if (!userAuthPassthrough) {
    return [];
  }

  const pendingStreakTrophies = userAuthPassthrough.filter(
    (i) =>
      i.type === 'trophy_earned' &&
      i.data &&
      i.data.trophyId === STREAK_TROPHY_ID
  );

  return pendingStreakTrophies;
}

function getStreakTrophiesNotifications() {
  const streakTrophies = getStreakTrophies();

  return streakTrophies.map((n) => ({
    onClick: () => {},
    label: 'notification-read',
    location: 'userMenu',
    meta: {
      trophy_name: 'There\'s No Place Like Home',
      trophy_img_url:
        'https://img.buzzfeed.com/store-an-image-prod-us-east-1/QcHvPNV8N.png',
    },
    type: 'teaser_trophy_added',
    url: '/auth/signin',
    triggered_at: n.created_at,
    isTeaser: true,
    teaserName: 'homepage_streak',
    trackingData: {
      'item_name': 'signin_to_view_trophy',
      'item_type': 'button',
      'subunit_name': 'homepage_streak',
      'subunit_type': 'teaser_notification',
    }
  }));
}

export function getTeaserNotifications() {
  return [
    ...getStreakTrophiesNotifications(),
  ];
}

export function removeTeaserNotifications() {
  const userAuthPassthrough = getLocalStorageItem(PASSTHROUGH_KEY);
  if (!userAuthPassthrough) {
    return;
  }

  const filtered = userAuthPassthrough.filter(
    (entry) => !TEASER_PASSTHROUGH_TYPES.includes(entry.type)
  );
  window.localStorage.setItem(PASSTHROUGH_KEY, JSON.stringify(filtered));
}
