import { h, Component } from 'preact';
import styles from './styles.scss';
import { destinationNav, discoverNav } from '../../../constants/bringme';
import { trackBringMeNavClick } from '../../tracking/google';
import { Config } from '../../context';

const breakpointLg = 1024;

const DropdownCaret = () => (
  <svg className={styles.caretDown} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
    <path
      d="M19 29.3L2.6 12.9c-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0L19 23.7l13.6-13.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8L19 29.3z"
      stroke="white" strokeWidth="5" />
  </svg>
);

export default class BringMeBrandNav extends Component {
  constructor() {
    super();
    this.state = {
      expanded: {
        destination: false,
        discover: false,
      },
      locationPath: '', // we have to infer the page to set aria-current based on URL
    };
  }

  componentDidMount() {
    this.setState({ locationPath: window.location.pathname });
  }

  toggleExpandedClick(name, isExpanded) {
    return (e) => {
      e.preventDefault();

      if (this.state[name] === isExpanded) {
        return;
      }

      const newState = {};

      // make sure everything else gets closed
      Object.keys(this.state.expanded).forEach(k => {
        newState[k] = k === name ? isExpanded : false;
      });

      this.setState({ expanded: newState });
    };
  }

  toggleExpandedHover(name, isExpanded) {
    return (e) => {
      // only fire on large screens
      if (window.innerWidth < breakpointLg) {
        return;
      }

      this.toggleExpandedClick(name, isExpanded)(e);
    };
  }

  clickHandler({ url, ga }) {
    return () => {
      if(ga === 'buzzfeed_ga') {
        trackBringMeNavClick({ url, ga });
      }
    };
  }

  render(props, state) {
    return (
      <nav className={styles.bringMeNav}>
        <Config.Consumer>
          {config => (
            <ul className={styles.container}>
              <li
                aria-expanded={state.expanded.destination}
                aria-haspopup="true"
                onMouseEnter={this.toggleExpandedHover('destination', true)}
                onMouseLeave={this.toggleExpandedHover('destination', false)}
                className={`${styles.menuItem} ${destinationNav.url === state.locationPath ? styles.isCurrentPage : ''}`}
              >
                <a className={styles.toggle}
                  aria-current={destinationNav.url === state.locationPath ? 'page' : null}
                  href={destinationNav.url}
                  onClick={this.clickHandler({ url: destinationNav.url, ga: config.ga })}
                >
                  <span>
                    {destinationNav.label}
                  </span>
                  <button
                    type="button"
                    className={styles.destinationToggle}
                    onClick={this.toggleExpandedClick('destination', !state.expanded.destination)}
                  >
                    <span className={styles.moreLabel}>Show submenu for {destinationNav.label} </span>
                    <DropdownCaret/>
                  </button>
                </a>
                <ul className={`${styles.subNav} ${styles.destinationContainer}`}>
                  {destinationNav.sub_nav.map(subNavItem => {
                    const isActiveSubNavItem = subNavItem.url === state.locationPath;
                    const subNavClass = subNavItem.disclaimer ? styles.withDisclaimer : '';
                    return (
                      <li className={subNavClass}>
                        <a
                          href={subNavItem.url}
                          aria-current={isActiveSubNavItem ? 'page' : null}
                          onClick={this.clickHandler({ url: subNavItem.url, ga: config.ga })}
                        >
                          {subNavItem.label}
                        </a>
                        {
                          subNavItem.disclaimer &&
                          <p className={styles.disclaimer}>{subNavItem.disclaimer}</p>
                        }
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className={styles.menuItem} aria-haspopup="true" aria-expanded={state.expanded.discover}>
                <button
                  className={`${styles.toggle} ${styles.discoverButton}`}
                  type="button"
                  onClick={this.toggleExpandedClick('discover', !state.expanded.discover)}
                >
                  <span className={styles.moreLabel}>Show submenu for Explore </span>
                  <span>Explore</span>
                  <DropdownCaret/>
                </button>
                <ul className={`${styles.subNav} ${styles.discoverContainer}`}>
                  {discoverNav.map(navItem => {
                    const isActiveNavItem = navItem.url === state.locationPath;

                    return (
                      <li className={`${styles.discoverItem} ${isActiveNavItem ? styles.isCurrentPage : ''}`}>
                        <a
                          href={navItem.url}
                          aria-current={isActiveNavItem ? 'page' : null}
                          onClick={this.clickHandler({ url: navItem.url, ga: config.ga })}
                        >
                          <span>
                            {navItem.label}
                          </span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          )}
        </Config.Consumer>
      </nav>
    );
  }
}
