export const destinationNav = {
  label: 'Destinations',
  page_name: 'travel-destinations',
  url: '/travel-destinations',
  sub_nav: [
    {
      label: 'United States',
      url: '/travel-destinations/united-states'
    },
    {
      label: 'Canada',
      url: '/travel-destinations/canada'
    },
    {
      label: 'Mexico',
      url: '/travel-destinations/mexico'
    },
    {
      label: 'Japan',
      url: '/travel-destinations/japan'
    },
    {
      label: 'Australia',
      url: '/travel-destinations/australia'
    },
    {
      label: 'Paris',
      url: '/travel-destinations/paris'
    },
    {
      label: 'London',
      url: '/travel-destinations/london'
    },
    {
      label: 'New York City',
      url: '/travel-destinations/new-york-city'
    },
    {
      label: 'Los Angeles',
      url: '/travel-destinations/los-angeles'
    }
  ]
};

export const discoverNav = [
  {
    label: 'Unique Experiences',
    page_name: 'travel-unique-experiences',
    url: '/travel-unique-experiences'
  },
  {
    label: 'Travel Products',
    page_name: 'travel-products',
    url: '/travel-products'
  },
  {
    label: 'Places To Eat',
    page_name: 'travel-food',
    url: '/travel-food'
  },
  {
    label: 'Travel Hacks',
    page_name: 'travel-hacks',
    url: '/travel-hacks'
  },
  {
    label: 'Budget Trips',
    page_name: 'travel-budget-trips',
    url: '/travel-budget-trips'
  },
];
