import { isToday, isYesterday } from 'date-fns';

export const PASSTHROUGH_KEY = 'user_auth_passthrough';
export const RETURN_VISIT_KEY = 'cmty_return_visits';
export const STREAK_DAYS_THRESHOLD = 5;
export const STREAK_TROPHY_ID = 29;
export const STREAK_EXPIRY = 1000 * 60 * 60 * 24 * 14;

/**
 * ======================================
 * TRACKING RETURN VISITS TO THE HOMEPAGE
 * ======================================
 *
 * Logged-in users who come to the homepage five days in a row get a trophy.
 * Logged-out users who come to the homepage five days in a row also get a
 * trophy, but not until they're signed up and/or logged in.
 *
 * This set of functions, with an entrypoint in **trackHomePageStreak**, is
 * responsible for tracking return visits to the homepage by logged-out users
 * and for recording when a streak has been achieved.
 *
 * Each time the page is loaded (and the header is on every page), the follo-
 * wing call chain is kicked off:
 *
 * trackHomepageStreak -> checkReturnVisits -> generateNewEntry ->
 * -> recordStreakAchieved
 *
 * checkReturnVisits reads an object in localStorage that keeps count of the
 * homepage view streak:
 *
 * {
 *   last_seen: '2020-08-05',
 *   consecutive_days: 1
 *   trophies_earned: [],
 * }
 *
 * It calls generateNewEntry, which is responsible for leaving, incrementing,
 * or resetting the consecutive_days counter.
 *
 * If checkReturnVisits returns true for a five day streak being achieved
 * and the trophy has not already been marked as earned in the return visit entry:
 * recordTrophyEarned adds the trophy to the list of earned trophies in the streak,
 * recordStreakAchieved adds an entry to the shared user_auth_passthrough key
 * in localStorage. This is an array of objects that follow this structure:
 *
 * {
 *   type: 'trophy_earned',
 *   created_at: <Date>,
 *   expire_at: <Date>,
 *   data: {
 *     trophyId: <Number>
 *   }
 * }
 *
 * The fact that the user is entitled is represented in this object, which is
 * read by bf_user_profile_ui if they ever become a logged-in user.
 */
export function trackHomepageStreak() {
  if (window.location.pathname !== '/') {
    return;
  }

  const is5DayStreak = checkReturnVisits(
    STREAK_DAYS_THRESHOLD,
    STREAK_TROPHY_ID
  );
  if (is5DayStreak) {
    recordStreakAchieved(STREAK_TROPHY_ID);
    recordTrophyEarned(STREAK_TROPHY_ID);
  }
}

export const formatDate = (date) => date.toISOString();

function recordStreakAchieved(streakTrophyId) {
  const content = localStorage.getItem(PASSTHROUGH_KEY) || '[]';
  let parsed = JSON.parse(content);
  parsed.sort((a, b) => a.created_at < b.created_at);
  /* Deduplicate user_auth_passthrough entries for this streak trophy; the user
   * only ever earns one of these before they log in. */
  parsed = parsed.filter((n) => n.data.trophyId !== streakTrophyId);

  const action = {
    type: 'trophy_earned',
    created_at: new Date(),
    expire_at: new Date(Date.now() + STREAK_EXPIRY),
    data: {
      trophyId: streakTrophyId,
    },
  };

  const toWrite = JSON.stringify([...parsed, action]);
  localStorage.setItem(PASSTHROUGH_KEY, toWrite);
  return toWrite;
}

function recordTrophyEarned(trophyEarnedId = 0) {
  const returnVisitEntryString = window.localStorage.getItem(RETURN_VISIT_KEY);

  try {
    if (returnVisitEntryString) {
      const returnVisitEntry = JSON.parse(returnVisitEntryString);
      const earnedTrophies = returnVisitEntry['trophies_earned'] || [];

      if (
        Array.isArray(earnedTrophies) &&
        trophyEarnedId &&
        !earnedTrophies.includes(trophyEarnedId)
      ) {
        window.localStorage.setItem(
          RETURN_VISIT_KEY,
          JSON.stringify({
            ...returnVisitEntry,
            trophies_earned: [...earnedTrophies, trophyEarnedId],
          })
        );
      }
    }
  } catch (error) {
    console.error(error);
    console.error(
      `Failed to parse cmty_return_visits, cannot record trophy ${trophyEarnedId} as earned`
    );
  }
}

export function generateNewEntry(oldEntry) {
  let dayCount = 1;

  if (!oldEntry) {
    return {
      last_seen: formatDate(new Date()),
      consecutive_days: 1,
      trophies_earned: [],
    };
  }

  /* If we last saw the user more than a day ago, reset their streak
   * If we saw them yesterday, increase their streak
   * If we saw them today, leave it as is */
  const previousDate = new Date(oldEntry['last_seen']);
  if (isToday(previousDate)) {
    dayCount = oldEntry['consecutive_days'];
  } else if (isYesterday(previousDate)) {
    dayCount = oldEntry['consecutive_days'] + 1;
  }

  return {
    last_seen: formatDate(new Date()),
    consecutive_days: dayCount,
    trophies_earned: oldEntry['trophies_earned'] || [],
  };
}

/*
 * Checks and updates the cmty_return_visit localStorage entry.
 * Returns true if streakDaysThreshold is reached and
 * streakTrophyId hasn't already been earned, false if not
 */
function checkReturnVisits(streakDaysThreshold, streakTrophyId) {
  let newEntry = generateNewEntry();
  let awardedTrophy = false;
  const existingEntryString = window.localStorage.getItem(RETURN_VISIT_KEY);

  try {
    if (existingEntryString) {
      const parsedEntry = JSON.parse(existingEntryString);
      newEntry = generateNewEntry(parsedEntry);
      if (
        newEntry['consecutive_days'] >= streakDaysThreshold &&
        !newEntry['trophies_earned'].includes(streakTrophyId)
      ) {
        awardedTrophy = true;
      }
    }
    /* If anything goes wrong in the parsing process just give up and start over
     * rather than try to interpret corrupted localStorage data. This is
     * considered a relatively ephemeral feature. */
  } catch (error) {
    console.error(error);
    console.error('Failed to parse cmty_return_visits, restarting streak');
  }

  window.localStorage.setItem(RETURN_VISIT_KEY, JSON.stringify(newEntry));
  return awardedTrophy;
}
