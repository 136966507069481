import styles from './styles.scss';
import { h, Component } from 'preact';
import cookies from '@buzzfeed/buzzblocks/js/services/cookies';
import { I18n } from '../context';
import { withTheme } from '../withTheme';

const editions = [
  { code: 'us', name: 'US' },
  { code: 'uk', name: 'UK' },
  { code: 'au', name: 'Australia' },
  { code: 'br', name: 'Brasil' },
  { code: 'ca', name: 'Canada' },
  { code: 'de', name: 'Deutschland' },
  { code: 'in', name: 'India' },
  { code: 'ja-jp', name: 'Japan' },
  { code: 'mx', name: 'Latam' },
];

const editionSelectId = 'js-header-edition-select';

class EditionSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      edition: 'us'
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      edition: cookies.get('country', 'us')
    });
  }

  handleSelectChange(event) {
    /* Temporary modifications for Germany and Brasil transition to new owners/sites */
    if (event.target.value === 'de') {
      window.location = '/de';
    } else if (event.target.value === 'br') {
      window.location = '/br';
    } else {
      cookies.set({
        name: 'country',
        value: event.target.value,
        domain: window.BZFD.Config.env === 'dev' ? 'buzzfeed.io' : 'buzzfeed.com',
        days: 180
      });
      window.location = '/';
    }
  }

  render({ theme }, { edition }) {
    return (
      <I18n.Consumer>
        {i18n =>
          <div className={styles.editionSelect}>
            <label className={theme.text} for={editionSelectId}>{i18n.edition}</label>
            <select id={editionSelectId} className={styles.select} value={edition} onChange={this.handleSelectChange}>
              {
                editions.map(({ code, name }) => <option data-bfa={`@a:Main-Nav;@d:${name};`} value={code}>{name}</option>)
              }
            </select>
          </div>
        }
      </I18n.Consumer>
    );
  }
}

export default withTheme(EditionSelect);
