import { h } from 'preact';
import { BuzzFeedOriginalsLogo } from '../logos/bfo';
import { BuzzFeedNewsBrandLogo, BuzzFeedNewsTagline } from './news';
import { AsIsBrandLogo, UseAsIsBrandLogo } from './as-is';
import { BringmeBrandLogo, UseBringmeBrandLogo } from './bringme';
import { PerolikeBrandLogo, UsePerolikeBrandLogo } from './perolike';
import { CocoaButterBrandLogo, UseCocoaButterBrandLogo } from './cocoabutter';
import { TastyhomeBrandLogo, UseTastyhomeBrandLogo } from './tastyhome';
import { GoodfulBrandLogo, UseGoodfulBrandLogo } from './goodful';
import { NiftyBrandLogo, UseNiftyBrandLogo } from './nifty';
import { AdobeBuzzFeedLogo } from './adobe';
import { LGBTQBrandLogo, UseLGBTQBrandLogo } from './lgbtq';
import { WorkAndMoneyBrandLogo, UseWorkAndMoneyBrandLogo } from './work-and-money';

export const createBrandLogoByName = brandName => ({
  asis: <AsIsBrandLogo />,
  bfo: <BuzzFeedOriginalsLogo />,
  bringme: <BringmeBrandLogo />,
  buzzfeed: <BuzzFeedOriginalsLogo />,
  goodful: <GoodfulBrandLogo />,
  adobe: <AdobeBuzzFeedLogo />,
  news: <BuzzFeedNewsBrandLogo />,
  nifty: <NiftyBrandLogo />,
  lgbtq: <LGBTQBrandLogo />,
  perolike: <PerolikeBrandLogo />,
  cocoabutter: <CocoaButterBrandLogo />,
  tastyhome: <TastyhomeBrandLogo />,
  'work-and-money': <WorkAndMoneyBrandLogo />
}[brandName]);

export const createBrandTaglineByName = brandName => ({
  news: <BuzzFeedNewsTagline />
}[brandName]);

export const MicroBrandLogo = ({ brandName, ...props}) => {
  switch (brandName) {
  case 'asis':
    return <UseAsIsBrandLogo {...props} />;
  case 'bringme':
    return <UseBringmeBrandLogo {...props} />;
  case 'goodful':
    return <UseGoodfulBrandLogo {...props} />;
  case 'nifty':
    return <UseNiftyBrandLogo {...props} />;
  case 'lgbtq':
    return <UseLGBTQBrandLogo {...props} />;
  case 'perolike':
    return <UsePerolikeBrandLogo {...props} />;
  case 'cocoabutter':
    return <UseCocoaButterBrandLogo {...props} />;
  case 'tastyhome':
    return <UseTastyhomeBrandLogo {...props} />;
  case 'work-and-money':
    return <UseWorkAndMoneyBrandLogo {...props} />;
  }

  return null;
};
