import { h, Component } from 'preact';
import { authRedirect } from '@buzzfeed/bf-utils/lib/user';
import { Link } from '../link';
import styles from './styles.scss';
import { withTheme } from '../withTheme';
import { UseWishlistIcon, UseWishlistIconAnimated } from '../svg/icons/wishlist';
import { I18n, Tracking } from '../context';
import Preview from './preview';
import storage from '@buzzfeed/bf-utils/lib/storage';
import { UseHeartIllustrationIcon } from '../svg/icons/heart-illustration';
import { UseCloseIcon } from '../svg/icons/close';
import WishlistTooltip from './wishlist-tooltip';

const EDITIONS = ['us'];
const WISHLIST_ICON_ID = 'js-wishlist-icon';
const WISHLIST_REMINDER_FLAG = 'wishlist_reminder';
const WISHLIST_PINK_DOT_FLAG = 'wishlist_pink_dot';


class WishlistIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enabled: EDITIONS.includes(props.edition),
      initialized: false,
      wishcount: 0,
      wishlink: '/wishlists/new',
      showSignIn: false,
      showPreview: false,
      showPinkDot: false,
      items: [],
      animateAdd: false,
      iconAnimate: false,
      showFirstProductTooltip: false,
      preview: []
    };
  }

  fireEvent(data) {
    const event = new CustomEvent('wishlistListener', {detail: {
      initiator: this.id,
      ...data
    }});
    window.dispatchEvent(event); // send event to main wishlist handler
  }

  getWishList() {
    this.fireEvent({
      action: 'getWishlist',
    });
  }

  wishlistUpdatedCallback(e) {
    // animate wishlist icon
    if (e.detail.action === 'add') {
      this.iconAnimation();
      this.setState((prevState) => ({
        ...prevState,
        showFirstProductTooltip: prevState.items.length === 0
      }));
    } else if (e.detail.action === 'added') {
      // show just added item in preview popup
      this.setState((prevState) => ({
        ...prevState,
        animateAdd: !!(e.detail.preview && e.detail.preview.length),
        items: [...e.detail.items, ...prevState.items],
        preview: [...(e.detail.preview || []), ...prevState.preview],
        wishcount: e.detail.items.length,
      }));
      // set timeout for animation
      setTimeout(function() {
        this.setState((prevState) => ({
          ...prevState,
          animateAdd: false,
        }));
      }.bind(this), 500);
    // broadcast message about wishlist updates
    } else if (e.detail.action === 'getWishlist' && e.detail.items) {
      this.setState((prevState) => {
        let showPinkDot = prevState.showPinkDot;
        // run once ofter get first data;
        if (!prevState.initialized) {
          showPinkDot = !storage.get(WISHLIST_PINK_DOT_FLAG) && e.detail.preview.length;

          // prevent showing popups on wishlist page
          if (window && window.location.pathname.indexOf('/wishlists/') !== 0 &&
            !storage.get(WISHLIST_REMINDER_FLAG) && e.detail.preview.length) {
            storage.set({
              key: WISHLIST_REMINDER_FLAG,
              value: 1,
              expires: 1000 * 60 * 60 * 24 //one day
            });
            // show preview reminder once a day
            setTimeout(() => {
              this.showPopUp('showPreview');
            }, 200);
          }
        }
        return {
          ...prevState,
          initialized: true,
          items: e.detail.items,
          preview: e.detail.preview,
          wishcount: e.detail.items.length,
          wishlink: e.detail.url || prevState.wishlink,
          showPinkDot
        };
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps && !this.props.showWishlistPopup && prevProps.showWishlistPopup) {
      this.setState((prevState) => ({
        ...prevState,
        showSignIn: false,
        showPreview: false,
      }));
    }

    if (prevProps && !this.props.isTemporarilyHidden && prevProps.isTemporarilyHidden) {
      if (this.state.showSignIn || this.state.showPreview) {
        this.props.toggleWishlistPopup();
      }
    }
  }

  componentDidMount() {
    window.addEventListener('wishlistHandler', this.wishlistUpdatedCallback.bind(this));
    this.getWishList();
  }

  componentWillUnmount() {
    window.removeEventListener('wishlistHandler', this.wishlistUpdatedCallback.bind(this));
  }

  iconAnimation() {
    this.setState({ iconAnimate: true });
    setTimeout(() => {
      this.setState({ iconAnimate: false });
    }, 600);
  }

  hidePinkDot() {
    this.setState({ showPinkDot: false});
    storage.set({
      key: WISHLIST_PINK_DOT_FLAG,
      value: 1,
      expires: 1000 * 60 * 60 * 24 //one day
    });
  }

  // hide all popups
  hidePopUp(params) {
    let { internalCall } = params;
    this.setState((prevState) => ({
      ...prevState,
      showSignIn: false,
      showPreview: false,
    }));
    // hide overlay if we click on wishlist content
    if (internalCall) {
      this.props.toggleWishlistPopup();
    }
    this.hidePinkDot();
  }

  showPopUp(state, cet) {
    if (!this.state[state]) {
      this.setState({ [state]: true });
      if (!this.props.isTemporarilyHidden) {
        this.props.toggleWishlistPopup();
      }
      const location = state.replace('show', '');
      cet.trackNavAction({
        location: 'wishlist' + location,
        action_value: 'show',
      });
    }
  }

  gotoWishlistPage(cet) {
    cet.trackNavClick({
      label: 'my_wishlist',
      location: 'mainNav',
      url: this.state.wishlink,
    });
    this.hidePinkDot();
    window.location = this.state.wishlink;
  }

  onIconClick(cet, e) {
    e.preventDefault();
    e.stopPropagation();

    // close all wishlist popups if it were opened
    if (this.state.showPreview || this.state.showSignIn) {
      this.hidePopUp({internalCall: true});
      // Show SingIn popup if user doesn't have preview items in wishlist
    } else if (!this.state.preview || !this.state.preview.length) {
      if (!this.props.userid) {
        this.showPopUp('showSignIn', cet);
      } else {
        this.gotoWishlistPage(cet);
      }
      // Show Preview popup
    } else {
      this.showPopUp('showPreview');
    }
    return false;
  }
  hideFirstProductTooltip() {
    this.setState((prevState) => ({
      ...prevState,
      showFirstProductTooltip: false
    }));
  }
  signIn() {
    this.hidePinkDot();
    authRedirect(this.state.redirectUrl);
  }

  render() {
    if (!this.state.enabled) {
      return null;
    }

    return (
      <Tracking.Consumer>
        {cet => {
          return (
            <div className={this.props.className
              ? `${styles.wishlist} ${styles[this.props.className]}` : styles.wishlist}>
              <a
                aria-controls={WISHLIST_ICON_ID}
                href={this.state.wishlink}
                location={'mainNav'}
                label={'my_wishlist'}
                className={`${styles.wishlistLink}`}
                aria-label={'my wishlist'}
                onClick={this.onIconClick.bind(this, cet)}
              >
                <Icon id="wishlist-icon" className={`${this.state.iconAnimate ? styles.animate : ''}`} isAnimate={this.state.iconAnimate}/>
                {this.state.showPinkDot ? <div className={styles.pinkDot} /> : ''}
              </a>
              {this.state.showFirstProductTooltip &&
                <WishlistTooltip
                  hideFirstProductTooltip={this.hideFirstProductTooltip.bind(this)}
                  isLoggedIn={this.props.userid}
                  signIn={this.signIn.bind(this)}
                />
              }
              {this.state.showPreview && !this.props.isTemporarilyHidden ?
                <div className={styles.popup}>
                  <Preview
                    userid={this.props.userid}
                    totalCount={this.state.wishcount}
                    items={this.state.preview}
                    animateAdd={this.state.animateAdd}
                    onClose={this.hidePopUp.bind(this, {
                      internalCall: true,
                      type: 'preview'
                    })}
                    wishlistLink={this.state.wishlink}
                    createImpressionHandler={cet.createImpressionHandler}
                  />
                </div>
                : ''}
              {this.state.showSignIn ?
                <div className={`${styles.popup} ${styles.popupSignin}`}>
                  <SignInTooltip
                    createImpressionHandler={cet.createImpressionHandler}
                    hidePinkDot={this.hidePinkDot.bind(this)}
                    onClose={this.hidePopUp.bind(this, {
                      internalCall: true,
                      type: 'signup'
                    })}
                  />
                </div>
                : ''}
            </div>
          );
        }}
      </Tracking.Consumer>
    );
  }
}

class SignInTooltip extends Component {
  constructor(props) {
    super(props);
    const redirectUrl = `https://${window.location.hostname.includes('stage') ? 'stage.' : ''}buzzfeed.com/wishlists/new`;
    this.state = {
      redirectUrl: redirectUrl,
      trackingData: {
        unit_type: 'nav',
        unit_name: 'main',
        item_name: 'wishlist_signup',
        item_type: 'button',
        target_content_type: 'auth',
        target_content_id: 'sign_in',
      }
    };
  }
  componentWillUnmount() {
    if (typeof this.detachImpressionHandler === 'function') {
      this.detachImpressionHandler();
    }
  }
  componentDidMount() {
    this.detachImpressionHandler = () => this.props.createImpressionHandler(this.base, {
      trackingData: this.state.trackingData
    });
  }
  signIn(e) {
    this.props.hidePinkDot();

    e.preventDefault();
    authRedirect(this.state.redirectUrl);
  }
  onCloseClick() {
    this.props.onClose();
  }

  render() {

    const additionalProps = {
      onClick: (e) => this.signIn(e)
    };
    return (
      <div className={styles.signInTooltip}>
        <UseCloseIcon
          onClick={() => this.onCloseClick()}
          width={22} height={22} aria-hidden={true} className={styles.close} />
        <UseHeartIllustrationIcon width={40} height={40} aria-hidden={true} className={styles.heart} />
        Collect the products you love from Shopping articles, and we'll save them here for you.
        <Link
          className={styles.ctaLink}
          href="/auth/signin"
          location={'wishlist'}
          label={'login'}
          aria-label={'login'}
          {...additionalProps}
          trackingData={this.state.trackingData}
        >Sign in to get started</Link>
      </div>
    );
  }
}

export const Icon = withTheme(({ theme, id, className, isAnimate }) => {
  if (isAnimate) {
    return (
      <UseWishlistIconAnimated className={`${styles.wishlistIcon} ${theme.wishlistIcon} ${className}`} role="img" aria-labelledby={id}>
        <I18n.Consumer>
          {i18n => <title id="wishlist-icon-animated">{i18n.wishlist}</title>}
        </I18n.Consumer>
      </UseWishlistIconAnimated>
    );
  } else {
    return (
      <UseWishlistIcon className={`${styles.wishlistIcon} ${theme.wishlistIcon} ${className}`} role="img" aria-labelledby={id}>
        <I18n.Consumer>
          {i18n => <title id={id}>{i18n.wishlist}</title>}
        </I18n.Consumer>
      </UseWishlistIcon>
    );
  }
});

export default WishlistIcon;
