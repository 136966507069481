import styles from './wishlist-tooltip.module.scss';
import { Icon } from './wishlist_icon';
import { h, Component } from 'preact';
// eslint-disable-next-line no-unused-vars
// import React from 'react';

class WishlistTooltip extends Component {
  constructor(props) {
    super(props);

    this.userNavEl = document.querySelector('.js-sticky-container');
  }

  componentDidMount() {
    if (window.innerWidth < 832) {
      Object.assign(this.userNavEl.style, {
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        'z-index': 500,
      });
    }
  }

  submitForm(event) {
    event.preventDefault();
    this.props.hideFirstProductTooltip();
    this.userNavEl.removeAttribute('style');
  }

  render() {
    return (
      <div className={styles.tooltip}>
        <div className={styles.tooltipText}>
          <span className={styles.tooltipTextBold}>
            You saved your first product!
          </span>{' '}
          {this.props.isLoggedIn && (
            <span>
              View your full Wishlist anytime by clicking on the{' '}
              <Icon id="wishlist-icon-small" className={styles.heart} /> in the main navigation.
            </span>
          )}
          {!this.props.isLoggedIn && (
            <span>Sign in to edit and access your full Wishlist from any device.</span>
          )}
        </div>
        {!this.props.isLoggedIn && (
          <div className={styles.buttons}>
            <div
              role="button"
              onKeyDown={this.submitForm.bind(this)}
              onClick={this.submitForm.bind(this)}
              className={`${styles.laterButton}`}
              tabIndex={0}
            >
              Maybe Later
            </div>
            <button
              onClick={this.props.signIn}
              className={`${styles.okButton} ${styles.button}`}
            >
              Sign In
            </button>
          </div>
        )}
        {this.props.isLoggedIn && (
          <div className={styles.buttons}>
            <button
              onClick={this.submitForm.bind(this)}
              className={`${styles.okButton} ${styles.button}`}
            >
              Got It
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default WishlistTooltip;
