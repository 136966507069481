import { h } from 'preact';
import Header from './header';
import { createDestinationLogoByName } from './shared/svg/logos';
import { HamburgerIcon } from './shared/svg/icons/hamburger';
import { SearchIcon } from './shared/svg/icons/search';
import { WishlistIcon, WishlistIconAnimated } from './shared/svg/icons/wishlist';
import { HeartIllustrationIcon } from './shared/svg/icons/heart-illustration';
import { SettingsIcon } from './shared/svg/icons/settings';
import { createBadgeSVGByName } from './shared/svg/badges';
import { BuzzFeedOriginalsLogo } from './shared/svg/logos/bfo';
import { BuzzFeedJpLogo } from './shared/svg/logos/bfo-jp';
import { CloseIcon } from './shared/svg/icons/close';
import { CaretIcon } from './shared/svg/icons/caret';
import { DefaultUserIcon } from './shared/svg/icons/default-user';
import { SparklesIcon } from './shared/svg/icons/sparkles';
import { createBrandLogoByName, createBrandTaglineByName } from './shared/svg/brands';

export const headerContainerId = 'js-header-container';

const SVGDefs = ({ children }) => (
  // https://stackoverflow.com/questions/40562818/gradients-hidden-using-svg-symbols
  <svg width="0" height="0" style="display: block;">
    <defs>{ children }</defs>
  </svg>
);

/**
 * This container is used by the client to remount the application instance.
 * It is diffed against shadow DOM tree reconstructed on the client side.
 * Be wary of changing its contents as it may affect initialisation process.
*/
const HeaderContainer = ({ ...props }) => (
  <div id={headerContainerId}>
    <Header {...props} />
  </div>
);

/**
 * Provides a shell for the header component, which is only rendered on the server.
 * Any static data referenced from the client code belongs here (i.e. JS globals or SVG definitions)
*/
const HeaderShell = (props) => {
  const { navItems = {}, brand } = props;
  const { destinations = [], badges = [] } = navItems;
  return (
    <div>
      <SVGDefs>
        {destinations.map(({ name }) => createDestinationLogoByName(name))}
        {badges.map(({ name }) => createBadgeSVGByName(name, brand))}
        {brand !== 'bfo' && createBrandLogoByName(brand)}
        {createBrandTaglineByName(brand)}
        <HamburgerIcon />
        <SearchIcon />
        <WishlistIcon />
        <WishlistIconAnimated />
        <HeartIllustrationIcon />
        <CloseIcon />
        <CaretIcon />
        <SettingsIcon />
        <BuzzFeedOriginalsLogo />
        <BuzzFeedJpLogo />
        <DefaultUserIcon />
        <SparklesIcon />
      </SVGDefs>
      <HeaderContainer {...props} />
      <script dangerouslySetInnerHTML={{ __html: `
      window.BZFD = window.BZFD || {};
      window.BZFD.__HEADER_STATE__ = ${JSON.stringify(props)};
    ` }} />
    </div>
  );
};

export default HeaderShell;
