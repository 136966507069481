import { h, Component, createRef } from 'preact';
import { authRedirect } from '@buzzfeed/bf-utils/lib/user';
import classnames from 'classnames';
import {
  isAmazon,
  getTags,
  setSubtags
} from '@buzzfeed/bf-utils/lib/affiliate-link';
import { Link } from '../link';
import styles from './preview.scss';
import { BaggyPeace as Fallback } from '../svg/icons/BaggyPeace';

import { UseCloseIcon } from '../svg/icons/close';
import { UseHeartIllustrationIcon } from '../svg/icons/heart-illustration';

export const MAX_ITEMS_IN_PREVIEW = 5;
const ORIGIN = 'wlp';

class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonText: this.props.userid ? 'View your full wishlist' : 'Sign in or create an account',
      trackingData: {
        unit_type: 'nav',
        unit_name: 'main',
        item_name: 'wishlist_preview',
        item_type: 'text',
        target_content_type: 'url',
        target_content_id: this.props.wishlistLink,
      }
    };
  }

  componentWillUnmount() {
    if (typeof this.detachImpressionHandler === 'function') {
      this.detachImpressionHandler();
    }
  }
  componentDidMount() {
    this.detachImpressionHandler = () => this.props.createImpressionHandler(this.base, {
      trackingData: this.state.trackingData
    });
  }

  onCloseClick() {
    this.props.onClose();
  }

  render() {
    const itemsCountClass = styles['items_count_' + Math.min(this.props.items.length, MAX_ITEMS_IN_PREVIEW + 1)];

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>Your Wishlist</div>
          <UseCloseIcon
            onClick={() => this.onCloseClick()}
            width={22} height={22} aria-hidden={true} className={styles.close} />
        </div>
        <div className={styles.text}>{`You have ${this.props.totalCount} saved product${this.props.totalCount > 1 ? 's' : ''}`}</div>
        <div className={`${styles.itemsWrapper} ${this.props.animateAdd ? styles.animate : ''}`}>
          <div className={`${styles.items} ${itemsCountClass}`}>
            { this.props.items.map((item, idx) => <PreviewProduct animateAdd={this.props.animateAdd} idx={idx} totalCount={this.props.totalCount} {...item} />) }
          </div>
        </div>
        {this.props.userid &&
          <div className={styles.footer}>
            <Link
              href={this.props.wishlistLink}
              location="wishlistPreview"
              label={'my_wishlist'}
              aria-label={'my wishlist'}
              className={styles.link}
              trackingData={this.state.trackingData}
            >View your full Wishlist</Link>
          </div>
        }
        {!this.props.userid &&
          <SignOutFooter wishlistLink={this.props.wishlistLink} trackingData={this.state.trackingData}/>
        }
      </div>
    );
  }
}
class SignOutFooter extends Component {
  constructor(props) {
    super(props);
    const redirectUrl = `https://${window.location.hostname.includes('stage') ? 'stage.' : ''}buzzfeed.com/wishlists/new`;
    this.state = {
      redirectUrl: redirectUrl
    };
  }
  signIn(e) {
    e.preventDefault();
    authRedirect(this.state.redirectUrl);
  }

  render() {
    let additionalProps = {
      onClick: (e) => this.signIn(e)
    };

    return (
      <div className={`${styles.signoutFooter}`}>
        <UseHeartIllustrationIcon width={40} height={40} aria-hidden={true} className={styles.heart} />
        <div className={styles.footerText}>Sign in to permanently save products, edit, and access your full wishlist from any device.</div>
        <Link
          href={this.props.wishlistLink}
          location="wishlistPreview"
          label={'my_wishlist'}
          aria-label={'my wishlist'}
          className={styles.signInButton}
          {...additionalProps}
          trackingData={{
            ...this.props.trackingData,
            item_name: 'wishlist_signup',
            item_type: 'button',
            target_content_type: 'auth',
            target_content_id: 'sign_in'
          }}
        >Sign in or create an account</Link>
      </div>
    );
  }
}

class PreviewProduct extends Component {

  render() {
    if (
      !this.props.product_data ||
      !this.props.product_data.title ||
      !this.props.product_data.img_url
    ) {
      return null;
    }

    let itemsToShow = MAX_ITEMS_IN_PREVIEW;
    if (this.props.animateAdd) {
      itemsToShow++;
    }
    if (this.props.idx > (itemsToShow - 1)) {
      return '';
    }

    // update origin tag for affiliate link
    let affiliate_link = this.props.affiliate_link;
    if (isAmazon(affiliate_link)) {
      const subtags = {
        origin: `bf-${ORIGIN}`,
        linkId: this.props.affiliate_link_id
      };
      affiliate_link = setSubtags(affiliate_link, subtags);
    } else {
      const _escapeStrRegex = str => {
        return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      };
      let _escapedPrefix = _escapeStrRegex('https://go.redirectingat.com/');
      let _fullREStrV1 =
        _escapedPrefix + '/?\\?id=(.*)&site=(.*)&xs=(.*)&url=(http.*)';
      let _fullREStrV2 =
        _escapedPrefix + '/?\\?id=(.*)&sref=(.*)&url=(http.*)&xs=(.*)';
      let _fullREV1 = new RegExp(_fullREStrV1);
      let _fullREV2 = new RegExp(_fullREStrV2);

      if (_fullREV1.test(affiliate_link) || _fullREV2.test(affiliate_link)) {
        const _xcustArr =
          decodeURIComponent(getTags(affiliate_link)['xcust'] || '').split('|') || '';
        _xcustArr[2] = `bf-${ORIGIN}`;
        _xcustArr[3] = '0:0';
        _xcustArr[4] = this.props.affiliate_link_id;
        affiliate_link = setSubtags(affiliate_link, { xcust: encodeURIComponent(_xcustArr.join('|')) });
        affiliate_link = decodeURIComponent(affiliate_link); // fix double encoding
      }
    }

    return (
      <Link
        href={affiliate_link}
        className={`${styles.item}`}
        target="_blank"
        location={'wishlistPreview'}
        label={'wishlist'}
        data-link-id={this.props.affiliate_link_id}
        data-affiliate={true}
      >
        {/* <img className={styles.image} src={this.props.product_data.img_url} alt={this.props.product_data.title} /> */}
        <ProductImage imgSrc={this.props.product_data.img_url} alt_text={this.props.product_data.title} />
        <div className={styles.dek}>
          <div className={styles.product_title}>{this.props.product_data.title}</div>
          <div className={styles.info}>
            <div className={styles.price}>{this.props.product_data.formatted_price}</div>
            {this.props.product_data.retailer &&
              <div className={styles.retailer}>on {this.props.product_data.retailer}</div>
            }
          </div>
        </div>
      </Link>
    );
  }
}
function ProductImage({
  imgClassName,
  imgWrapperClassName,
  imgSrc,
  alt_text,
}) {
  const ref = createRef();

  function onImageError() {
    const current = ref.current;
    current.querySelector('img').style.display = 'none';
    current.querySelector('svg').style.display = 'block';
  }

  // only load images with the correct protocol
  if (imgSrc && !imgSrc.match(/^https/)) {
    imgSrc = null;
  }

  return (
    <div ref={ref} className={classnames(styles.imageWrapper, imgWrapperClassName)}>
      {imgSrc ? (
        <img
          className={classnames(styles.image, imgClassName)}
          src={imgSrc}
          alt={alt_text}
          onError={onImageError}
        />
      ) : null}
      <Fallback className={classnames(styles.fallback, imgClassName)} />
    </div>
  );
}

export default Preview;
